// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getToken } from "../../../components/src/AuthService";
export const configJSON = require("./config");

interface IErrorResponse {
  errors: {
    message: string
  }
}

export interface IContact {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: number;
    profile_pic_url: { url: string } | null;
    full_phone_number: string;
  };
}

export interface INormalizedContact {
  id: number;
  fullName: string;
  phone: string;
  email: string;
  checked: boolean;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  contacts: IContact[];
  isGettingContacts: boolean;
  invitationSubject: string;
  isAddContactDialog: boolean;
  isBulkInviteDialog: boolean;
  selectedContacts: IContact[];
  message: string;
  messageType: "success" | "error";
}

interface SS {
  id: any;
}

export default class ReviewInviteController extends BlockComponent<
  Props,
  S,
  SS
> {
  messageTimer: NodeJS.Timeout | null = null;
  getContactsApiCallId: string = "";
  sendReviewInvitesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      contacts: [],
      isGettingContacts: true,
      invitationSubject: `${configJSON.reviewTemplate}<b>${window.location.origin}</b>`,
      selectedContacts: [],
      isAddContactDialog: false,
      isBulkInviteDialog: false,
      message: "",
      messageType: "success",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.getContactsList();
  }

  handleContactsSelectionChange = (
    _event: React.ChangeEvent<{}>,
    newValue: unknown
  ) => {
    this.setState({ selectedContacts: newValue as IContact[] });
  };

  closeReviewInvite = () => {
    this.handleRedirection("Dashboard");
  };

  handleClose = (_event: React.MouseEvent, reason: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    this.closeReviewInvite();
  };

  openAddContactDialog = () => {
    this.setState({ isAddContactDialog: true });
  };

  closeAddContactDialog = () => {
    this.setState({ isAddContactDialog: false });
  };

  openBulkInviteDialog = () => {
    this.setState({ isBulkInviteDialog: true });
  };

  closeBulkInviteDialog = () => {
    this.setState({ isBulkInviteDialog: false });
  };

  normalizeContacts = (contacts: IContact[]): INormalizedContact[] => {
    const selectedContactIds = this.state.selectedContacts.map(
      (contact) => contact.attributes.id
    );
    return contacts.map((contact) => {
      const {
        attributes: { id, first_name, last_name, full_phone_number, email },
      } = contact;

      return {
        id,
        fullName: `${first_name} ${last_name}`,
        phone: full_phone_number,
        email,
        checked: selectedContactIds.includes(id),
      };
    });
  };

  toggleCheckBulkContact = (contactId: number) => {
    this.setState((prevState) => {
      const isAlreadyAdded = prevState.selectedContacts.some(
        (contact) => contact.attributes.id === contactId
      );
      const currentContact = prevState.contacts.filter(
        (contact) => contact.attributes.id === contactId
      );
      const selectedContacts = isAlreadyAdded
        ? prevState.selectedContacts.filter(
            (contact) => contact.attributes.id !== contactId
          )
        : [...prevState.selectedContacts, ...currentContact];
      return {
        selectedContacts,
      };
    });
  };

  addContact = (contact: IContact) => {
    this.setState((prevState) => {
      return {
        selectedContacts: [...prevState.selectedContacts, contact],
        contacts: [contact, ...prevState.contacts],
      };
    });
  };

  handleRedirection = (pageName: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleReviewInviteClick = () => {
    this.setState({ loading: true });
    const { selectedContacts, invitationSubject } = this.state;
    const selectedContactsIds = selectedContacts.map((contact) => contact.id);

    const message =  invitationSubject.replace(/<\/?b>|<br\s*\/?>/g, "")
    const invite = {
      type: "review",
      recipient_ids: selectedContactsIds,
      invite_message: message,
    };

    const httpBody = {
      invite,
    };

    const headerData = {
      "Content-Type": configJSON.applicationContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendReviewInvitesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteFriendEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getContactsList = () => {
    this.setState({ isGettingContacts: true });
    const token = getToken();

    const headerData = {
      "Content-Type": configJSON.applicationContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  showMessage = (
    message: string,
    messageType: "error" | "success",
    callback?: () => void
  ) => {
    this.setState({
      message,
      messageType,
    });
    this.messageTimer = setTimeout(() => {
      this.setState({ message: "" });
      callback && callback();
    }, 3000);
  };

  handleApiErrorResponses = (apiRequestCallId: string, responseJson: IErrorResponse) => {
    if(apiRequestCallId === this.sendReviewInvitesApiCallId) {
      this.showMessage(responseJson.errors.message, "error")
    }
  }

  async receive(_from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.error && !responseJson.errors) {
        if (apiRequestCallId === this.getContactsApiCallId) {
          this.setState({
            contacts: responseJson.data,
            isGettingContacts: false,
          });
        }

        if (apiRequestCallId === this.sendReviewInvitesApiCallId) {
          this.showMessage("Review invites sent successfully", "success", () =>
            this.handleRedirection("Dashboard")
          );
        }
      } else {
        this.handleApiErrorResponses(apiRequestCallId, responseJson)
      }
    }
  }
}
// Customizable Area End
