// Customizable Area Start
import React, { CSSProperties } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  withStyles,
  Avatar,
  Button,
} from "@material-ui/core";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";

import ContactsListContainerController, {
  NormalizedContact,
  Props,
} from "./ContactsListContainerController.web";
import {
  AddOutlined,
  ChatBubbleOutlineOutlined,
  CheckCircleOutlineRounded,
  ListOutlined,
  MailOutlineRounded,
  MoreVertRounded,
  SmartphoneRounded,
} from "@material-ui/icons";
import { contactsPlay, groupButton } from "./assets";
import { ContactsListViews } from "./enums";
import AddContactModal from "./AddContactModal.web";
import ContactsListAddContactsFile from "./ContactsListAddContactsFile.web";
import CustomLoader from "../../../components/src/CustomLoader.web";

export class ContactsListContainer extends ContactsListContainerController {
  constructor(props: Props) {
    super(props);
  }

  handlePost = (contact: NormalizedContact) => {
    let postContent: string;
    if (typeof contact.post !== "string") {
      postContent = `Posted ${contact.post?.days_ago} days ${contact.post?.hours_ago} hours ${contact.post?.minutes_ago} minutes ago`;
    } else {
      if (contact.post === this.handleReview(contact)) {
        postContent = ""; 
      } else {
        postContent = contact.post; 
      }
    }
    return postContent;
  }
  
  handleReview = (contact: NormalizedContact) => {
    let reviewContent: any;
    if (contact?.review?.rating) {
      reviewContent = `Left you ${contact.review.rating} star review`;
    } else {
      if (contact.review) {
        reviewContent = contact.review;
      } else {
        reviewContent = "No ratings found"; 
      }
    }
   return reviewContent;
  }
  
  render() {
    const { classes } = this.props;
    const {
      filters,
      normalizedContacts,
      currentView,
      contactToEdit,
    } = this.state;

    return (
      <Container
        disableGutters
        data-test-id="container"
        maxWidth={false}
        className={classes.mainContainer}
        onClick={(e) => {
          e.stopPropagation();
          this.toggleContactMenu();
        }}
      >
        <Container className={classes.content}>
          <Box className={classes.contactsMobileHeader}>
            <Typography className={classes.mobileHeaderText}>
              {this.allContacts}
            </Typography>
            <CheckCircleOutlineRounded />
          </Box>
          <Box className={classes.filtersContainer}>
            {filters.map((filter) => (
              <Button
                id={`button${filter.name.replace(" ", "")}`}
                key={filter.name}
                style={{
                  ...webStyles.filterItem,
                  backgroundColor: this.getStyleByCondition(
                    filter.checked,
                    "#000",
                    "#F1F5F9"
                  ),
                  color: this.getStyleByCondition(
                    filter.checked,
                    "#FFF",
                    "#334155"
                  ),
                }}
                onClick={() => this.checkFilter(filter.name)}
              >
                {filter.name === "All" && <StarBorderOutlinedIcon />}
                {filter.name === "Banned Users" && (
                  <ChatBubbleOutlineOutlined />
                )}
                {filter.name === "Opt Outs" && <ListOutlined />}
                <Typography>{filter.name}</Typography>
              </Button>
            ))}

            <Button
              id="setAddContactSceenButton"
              onClick={() => {
                this.checkFilter("");
              }}
              style={{
                ...webStyles.filterItem,
                backgroundColor: this.getStyleByCondition(
                  currentView === ContactsListViews.ADD_CONTACT,
                  "#000",
                  "#F1F5F9"
                ),
                color: this.getStyleByCondition(
                  currentView === ContactsListViews.ADD_CONTACT,
                  "#FFF",
                  "#334155"
                ),
              }}
            >
              <AddOutlined />
              <Typography>Add</Typography>
            </Button>
          </Box>
          {currentView === ContactsListViews.LIST && (
            <Box id="contactsContainer" className={classes.contactsContainer}>
              <CustomLoader loaderSize={24} isLoading={this.state.isLoading}/>
              {normalizedContacts.map((contact) => (
                <Box
                  key={String(contact.id)}
                  className={classes.contactItem}
                  data-test-id="contactItem"
                >
                  <Box className={classes.contactInfo}>
                    <Box className={classes.contactInfoTop}>
                      <Box className={classes.contactInfoTopLeftSide}>
                        <Box className={classes.avatarAndNameContainer}>
                          <Avatar
                            src={contact.profile_pic_url}
                            alt={contact.fullName}
                            className={classes.avatar}
                          />
                          <Typography className={classes.contactName}>
                            {contact.fullName}
                          </Typography>
                        </Box>

                        <Box className={classes.actionsContainer}>
                          <Button
                          data-test-id="showContact"
                            id={`showUser-${contact.id}`}
                            className={classes.viewProfileButton}
                            style={{ width: 135 }}
                            onClick={() => {
                              this.showContact(contact.id);
                            }}
                          >
                            {this.viewProfile}
                          </Button>
                          <Button className={classes.actionButton}>
                            <img src={groupButton} alt="add button" />
                          </Button>
                          <Button className={classes.actionButton}>
                            <img src={contactsPlay} alt="contact play" />
                          </Button>
                        </Box>
                      </Box>

                      <Box className={classes.contactMenu}>
                        {contact.is_banned_user ? (
                          <Button
                          data-test-id=""
                            id={`unbanUser-${contact.id}`}
                            className={classes.unbanUserText}
                            onClick={() => {
                              this.handleBanStatus(contact.id, false);
                            }}
                          >
                            {this.unbanUser}
                          </Button>
                        ) : (
                          <>
                            <Button
                              id={`contactMenuButton-${contact.id}`}
                              className={classes.dotsMenu}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.toggleContactMenu(contact.id);
                              }}
                            >
                              <MoreVertRounded />
                            </Button>
                            <Box
                              className={classes.menuList}
                              style={{
                                display: this.getStyleByCondition(
                                  contact.isActionMenuOpened,
                                  "flex",
                                  "none"
                                ),
                              }}
                            >
                              <Button
                                id={`openEditModal-${contact.id}`}
                                className={classes.menuItem}
                                onClick={() => 
                                  this.openEditContactModal({
                                    id: contact.id,
                                    firstName: contact.firstName,
                                    lastName: contact.lastName,
                                    email: contact.email,
                                    fullPhoneNumber: `+${contact.phone}`,
                                  })
                                }
                              >
                                {this.editUser}
                              </Button>
                              <Button
                                id={`banUser-${contact.id}`}
                                className={classes.menuItem}
                                onClick={() => {
                                  this.handleBanStatus(contact.id, true);
                                }}
                              >
                                {this.banUserText}
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      className={classes.contactInfoBottom}
                      style={{
                        display: this.getStyleByCondition(
                          window.innerWidth <= 960,
                          contact.isContactInfoOpened ? "flex" : "none",
                          "flex"
                        ),
                      }}
                    >
                      <Button
                        className={classes.viewProfileButtonMobile}
                        style={{ width: 135 }}
                      >
                        {this.viewProfile}
                      </Button>
                      <Box className={classes.contactMailAndPhoneContainer}>
                        <Box className={classes.userContact}>
                          <Box className={classes.iconContainer}>
                            <SmartphoneRounded />
                          </Box>
                          <Typography className={classes.contact}>
                            {`+${contact.phone}`}
                          </Typography>
                        </Box>
                        <Box className={classes.userContact}>
                          <Box className={classes.iconContainer}>
                            <MailOutlineRounded />
                          </Box>
                          <Typography className={classes.contact}>
                            {contact.email}
                          </Typography>
                        </Box>
                      </Box>
                      <Button className={classes.complianceButton}>
                        {this.complianceInfo}
                      </Button>
                    </Box>
                  </Box>
                  <Box className={classes.contactActivityInfo}>
                    {this.handlePost(contact) &&
                    <Box className={classes.activityInfoContainer}>
                      <Box className={classes.activityInfoDot} />
                      <Typography className={classes.activityInfoText}>
                        {this.handlePost(contact)}
                      </Typography>
                    </Box>
                    }
                    <Box className={classes.activityInfoContainer}>
                      <Box className={classes.activityInfoDot} />
                      <Typography className={classes.activityInfoText}>
                        {this.handleReview(contact)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {currentView === ContactsListViews.ADD_CONTACT && (<>
            <Box className={classes.addContactContainer}>
              <AddContactModal
                currentStep={currentView}
                setStep={() => this.setCurrentView(ContactsListViews.LIST)}
                hideBackBtn={true}
              />
              <div className={classes.verticalDivider} />
              <ContactsListAddContactsFile 
                setStep={() => this.setCurrentView(ContactsListViews.LIST)}
                setContactsFiles={this.setContactsFiles}
                sendContactsFile={this.sendContactsFile}
                contactFile={this.state.contactsFiles}
                acceptModal={this.state.acceptModal}
                handleModalClose={this.handleModalClose}
              />
            </Box>
            <Button data-test-id="backButton" id="backButton" className={classes.backButton} style={{ ...webStyles.button }} onClick={() => this.setCurrentView(ContactsListViews.LIST)}>Back</Button>
      </>
          )}
          {contactToEdit && (
            <Box
              className={classes.modalContainer}
              onClick={this.closeEditContactModal}
            >
              <AddContactModal
                currentStep={currentView}
                setStep={this.closeEditContactModal}
                contactData={contactToEdit}
              />
            </Box>
          )}
        </Container>
      </Container>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "17px 30px",
      backgroundColor: "#F1F5F9",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },

    modalContainer: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 99999,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      background: "rgba(0, 0, 0, 0.38)",
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },

    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      height: "100%",
      padding: "24px 9px 24px 24px",
      maxWidth: "unset",
      borderRadius: "8px 8px 32px 8px",
      backgroundColor: "#FFF",
      gap: "18px",

      [theme.breakpoints.down("sm")]: {
        borderRadius: "unset",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    contactsMobileHeader: {
      display: "none",
      alignSelf: "stretch",
      gap: "18px",
      justifyContent: "flex-end",
      padding: "19px 33px",
      background: "#000",
      color: "#FFF",

      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },

    mobileHeaderText: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },

    filtersContainer: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
      gap: "4px",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    contactsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      gap: "18px",
      paddingRight: "10px",

      overflowY: "auto",
      scrollbarWidth: "none",
      msOverflowStyle: "none",

      [theme.breakpoints.down("sm")]: {
        paddingLeft: "10px",
      },

      "&::-webkit-scrollbar": {
        width: "5px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#CBD5E1",
      },
    },

    contactItem: {
      display: "flex",
      justifyContent: "space-between",
      height: "160px",
      padding: "16px 8px",
      gap: "25px",
      borderRadius: "10px",

      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },

      "&:nth-child(even)": {
        backgroundColor: "#F3F6F9",
      },
    },

    contactInfo: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",
      flexGrow: 1,
    },

    contactInfoTop: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      paddingBottom: "18px",
      borderBottom: "1px solid #D4D4D4",

      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },

    contactInfoTopLeftSide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "25px",

      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
    },

    dotsMenu: {
      minWidth: "unset",
      padding: 0,
    },

    contactMenu: {
      position: "relative",
    },

    unbanUserText: {
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      textTransform: "unset",
    },

    menuList: {
      position: "absolute",
      top: 0,
      left: 0,

      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "2px",
      padding: "6px",
      backgroundColor: "#fff",
      zIndex: 1,
      textTransform: "none",

      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",

      [theme.breakpoints.down("sm")]: {
        right: "40%",
        left: "unset",
      },
    },

    menuItem: {
      display: "flex",
      width: "270px",
      height: "36px",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: "8px",
      paddingLeft: "32px",
      paddingRight: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "unset",

      "&:hover": {
        backgroundColor: "#FFC629",
        cursor: "pointer",
      },
    },

    avatarAndNameContainer: {
      display: "flex",
      alignItems: "center",
      width: "200px",
      gap: "10px",

      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
    },

    avatar: {
      width: "30px",
      height: "30px",
    },

    contactName: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "capitalize",
    },

    actionsContainer: {
      display: "flex",
      gap: "15px",

      [theme.breakpoints.down("sm")]: {
        gap: "8px",
      },
    },

    actionButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "29px",
      width: "29px",
      minWidth: "29px",
      borderRadius: "29px",
      backgroundColor: "#FFC629;",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "capitalize",
    },

    viewProfileButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "29px",
      width: "29px",
      minWidth: "29px",
      borderRadius: "29px",
      backgroundColor: "#FFC629;",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "capitalize",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    viewProfileButtonMobile: {
      display: "none",
      justifyContent: "center",
      alignItems: "center",
      height: "29px",
      width: "29px",
      minWidth: "29px",
      borderRadius: "29px",
      backgroundColor: "#FFC629;",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "22px",
      textTransform: "capitalize",

      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },

    contactInfoBottom: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      paddingTop: "18px",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "10px",
        paddingTop: 0,
      },
    },

    contactMailAndPhoneContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "80px",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        gap: "10px",
        alignItems: "flex-start",
      },
    },

    userContact: {
      display: "flex",
      alignItems: "center",
      gap: "17px",
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "36px",
      height: "36px",
      borderRadius: "50%",
      backgroundColor: "#F1F5F9",

      "& > *": {
        width: "18px",
      },
    },

    contact: {
      fontSize: "14px",
    },

    complianceButton: {
      display: "flex",
      width: "133px",
      height: "27px",
      borderRadius: "29px",
      backgroundColor: "#334155",
      color: "#FFF",
      textTransform: "capitalize",
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "22px",
      border: "1px solid #334155",

      "&:hover": {
        color: "#334155",
      },
    },

    contactActivityInfo: {
      display: "flex",
      width:"300px",
      flexWrap:"wrap",
      flexDirection: "column",
      padding: "0 20px",
      gap:"10px",
      textTransform:"capitalize",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    activityInfoContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "6px",
    },

    activityInfoDot: {
      width: "10px",
      height: "10px",
      minWidth: "10px",
      maxWidth: "10px",
      backgroundColor: "#DC2626",
      borderRadius: "50%",
    },

    activityInfoText: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
    },

    addContactContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "95px",
      alignItems: "center",
    },

    verticalDivider: {
      flexGrow: 1,
      width: "1px",
      height: "650px",
      backgroundColor: "#64748B",
    },

    backButton: {
      backgroundColor: "#000",
      color: "#FFF",

      "&:hover": {
        backgroundColor: "#000",
      },
    },
  });

const webStyles: Record<string, CSSProperties> = {
  filterItem: {
    display: "flex",
    height: "32px",
    padding: "6px 8px",
    alignItems: "center",
    gap: "4px",
    borderRadius: "26px",
    textTransform: "capitalize",
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "360px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
  },
};

export default withStyles(styles)(ContactsListContainer);
// Customizable Area End
