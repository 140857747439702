// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getToken } from "../../../components/src/AuthService";
import { AfterSignupModalSteps } from "./enums";

export interface File {
  id?: string;
  fileSource?: string;
  path?: string;
  preview: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name: string;
  size?: number;
  type: string;
  webkitRelativePath?: string;
}

export const configJSON = require("./config");

export type Props = {
  id?: string;
  classes?: any;
  navigation?: any;
  socialPhoneNumber: string;
  closeModal: () => void;
};

interface S {
  currentStep: AfterSignupModalSteps;
  file?: Blob[];
  privacyPolicyText: string;
  invitesContacts: string[];
  error: string;
}

interface SS {
  navigation: any;
}

export default class AfterSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPrivacyPolicyApiCallId: string = "";
  sendProfileImageApiCallId: string = "";
  updateFirstLoginStatusApiCallId: string = "";
  sendInvitesApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      currentStep: AfterSignupModalSteps.WELCOME,
      file: undefined,
      privacyPolicyText: "",
      invitesContacts: [],
      error: "",
    };
  }

  async componentDidMount(): Promise<void> {
    this.getPrivacyPolicy();
  }

  async componentWillUnmount(): Promise<void> {
    this.updateFirstLoginStatus();
  }

  setStep = (step: AfterSignupModalSteps) => {    
    this.setState({
      currentStep: step,
    });
  };

  setInvitesContacts = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    field: 'firstName' | 'lastName' | 'email'
  ) => {
    const fieldIndex = index * 3 + ['firstName', 'lastName', 'email'].indexOf(field);
    const updatedContacts = [...this.state.invitesContacts];
    updatedContacts[fieldIndex] = event.target.value;
    this.setState({ invitesContacts: updatedContacts});
  };

  setPicture = (file: any) => {
    this.setState({ file });
  };

  getPrivacyPolicy = () => {
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  sendProfilePic = () => {
    if (!this.state.file) return;

    const formData = new FormData();

    formData.append("data[profile_pic]", this.state.file[0]);

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendProfileImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfilePicEnpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  updateFirstLoginStatus = () => {
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
        token: getToken()
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFirstLoginStatusEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  sendInvites = () => {
    const httpBody = {
        invite: {
            recipient_phones: {
                phone: [
                ],
                email: this.state.invitesContacts.filter((_, index) => (index + 1) % 3 === 0)
            },
            invite_message:null,
            recipient_names: this.state.invitesContacts
            .map((contact, index, array) => {
              if ((index + 1) % 3 !== 0 && (index + 2) % 3 !== 0 && index + 1 < array.length) {
                  return contact + " " + array[index + 1];
              }
              return null;
          })
          .filter((elem, indx) => elem !== null) as string[]
        }
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendInvitesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.dashboarContentType,
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.firstLoginInviteEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {
      this.handleFetchPolicyAPIResponse(message);
    }

    if (apiRequestCallId === this.sendProfileImageApiCallId) {
      this.handleSendProfilePicAPIResponse(message);
    }

    if (apiRequestCallId === this.sendInvitesApiCallId) {
      this.handleSendInvites(message);
    }
  }

  handleFetchPolicyAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.data) {
      this.setState({
        privacyPolicyText: responseJson.data[0]?.attributes.description || "",
      });
    }
  };

  handleSendProfilePicAPIResponse = (message: Message) => {
    message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  };

  handleSendInvites = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if(responseJson?.message) {
      this.props.closeModal();
    }
  };
}
// Customizable Area End
