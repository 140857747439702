// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { InviteFriendsStepType } from "./enums";
import { getToken } from "../../../components/src/AuthService";

export const configJSON = require("./config");

export interface Contact {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: number;
    full_phone_number: string;
    profile_pic_url: {
      url: string
    } | null
  };
}

export interface NormalizedContact {
  id: number;
  fullName: string;
  phone: string;
  email: string;
  checked: boolean;
}

export type Props = {
  id?: string;
  classes?: any;
  navigation?: any;
  closeModal: (e?: React.SyntheticEvent) => void;
};

interface S {
  stepType: InviteFriendsStepType;
  contacts: Contact[];
  normalizedContacts: NormalizedContact[];
  inviteMessage: string;
  link: string;
  apiSuccessResponseMessage: string;
  apiErrorResponseMessage: string;
}

interface SS {
  navigation: any;
}

export default class InviteFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  contactsApiCallId: string = "";
  inviteFriendsApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      stepType: InviteFriendsStepType.INVITE_FRIEND,
      contacts: [],
      normalizedContacts: [],
      inviteMessage: this.template,
      link: window.location.origin,
      apiSuccessResponseMessage: "",
      apiErrorResponseMessage: "",
    };
  }

  template = configJSON.inviteTemplate;

  async componentDidMount() {
    super.componentDidMount();
    this.fetchContacts();
  }

  setStep = (step: InviteFriendsStepType) => {
    this.setState({ stepType: step });
  };

  addContact = (contact: Contact) => {
    this.setState((prevState) => ({
      contacts: [...prevState.contacts, contact],
      normalizedContacts: [
        ...prevState.normalizedContacts,
        ...this.normalizeContacts([contact]),
      ],
    }));
    this.checkContact([contact.attributes.id]);
  };

  setInviteMessage = (inviteMessage: string) => {
    this.setState({ inviteMessage });
  };

  setLink = (link: string) => {
    this.setState({ link });
  };

  checkContact = (contactsIds: number[] | undefined = []) => {
    this.setState((prevState) => ({
      normalizedContacts: prevState.normalizedContacts.map((contact) => {
        if (contactsIds.includes(contact.id)) {
          return {
            ...contact,
            checked: true,
          };
        }

        return { ...contact, checked: false };
      }) as NormalizedContact[],
    }));
  };

  checkBulkContact = (contactId: number) => {
    this.setState((prevState) => ({
      normalizedContacts: prevState.normalizedContacts.map((contact) => {
        if (contactId === contact.id) {
          return {
            ...contact,
            checked: !contact.checked,
          };
        }

        return contact;
      }),
    }));
  };

  onCloseSnackbar = () => {
    const apiErrorResponseMessage = this.state.apiErrorResponseMessage;
    this.setState({
      apiSuccessResponseMessage: "",
      apiErrorResponseMessage: "",
    });

    if (!apiErrorResponseMessage) {
      this.props.closeModal();
    }
  };

  normalizeContacts = (contacts: Contact[]): NormalizedContact[] => {
    return contacts.map((contact) => ({
      id: contact.attributes.id,
      fullName: `${contact.attributes.first_name} ${contact.attributes.last_name}`,
      phone: contact.attributes.full_phone_number,
      email: contact.attributes.email,
      checked: false,
    }));
  };

  fetchContacts(): boolean {
    const requestHeaders = {
      "Content-Type": configJSON.inviteFriendApiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSubmitInvite = () => {
    const { normalizedContacts, inviteMessage, link } = this.state;

    const selectedContactsIds = normalizedContacts
      .filter((contact) => contact.checked)
      .map((contact) => contact.id);

    const preparedMessage = inviteMessage.replace("[Link]", link);

    const invite = {
      recipient_ids: selectedContactsIds,
      invite_message: preparedMessage,
    };

    const httpBody = {
      invite,
    };

    const headerData = {
      "Content-Type": configJSON.dashboarContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inviteFriendsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      selectedContactsIds.length > 1
        ? configJSON.bulkInviteFriendEndPoint
        : configJSON.inviteFriendEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.contactsApiCallId) {
      this.handleFetchContactsAPIResponse(message);
    }

    if (apiRequestCallId === this.inviteFriendsApiCallId) {
      this.handleInviteFriendAPIResponse(message);
    }
  }

  handleFetchContactsAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.error || responseJson?.errors?.length) {
      this.setState({ apiErrorResponseMessage: "Unable to get contacts" });
      return;
    }

    if (responseJson?.data) {
      this.setState({
        contacts: responseJson.data,
        normalizedContacts: this.normalizeContacts(responseJson.data),
      });
    }
  };

  handleInviteFriendAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && (responseJson?.errors || responseJson?.error)) {
      this.setState({ apiErrorResponseMessage: "Invitation didn't sent" });
    }

    if (responseJson.message === "Invite Sent Successfully") {
      this.setState({
        apiSuccessResponseMessage: "Invite has been sent successfully",
      });
    }
  };
}
// Customizable Area End
