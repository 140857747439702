// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { getMediafileType } from "../../utilities/src/GetMediafileType";
import { File } from "./types";
export const configJSON = require("./config");

interface ITikTokMedia {
  id: string
  title: string
  video_description: string
  duration: number
  cover_image_url: string
  share_url: string
  embed_link: string
}

export interface Props {
  navigation: any;
  handleGoBack: () => void;
  setFiles: (files: File[]) => void;
}

interface S {
  isLoading: boolean;
  apiError: string;
  tikTokMedia: ITikTokMedia[];
  selectedMediaIds: string[];
}

interface SS {
  navigation: any;
}

export default class TikTokLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTikTokMediaApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      apiError: "",
      tikTokMedia: [],
      selectedMediaIds: [],
    };
  }

  async componentDidMount() {
    this.getTikTokMedia();
  }

  initiateTikTokLogin = () => {
    const clientId = configJSON.TIK_TOK_APP_ID;
    const redirectUri = `${window.location.origin}/tiktok_auth`;
    let url = 'https://www.tiktok.com/v2/auth/authorize/';

    url += `?client_key=${clientId}`;
    url += '&scope=user.info.basic,video.list';
    url += '&response_type=code';
    url += `&redirect_uri=${redirectUri}`;

    window.open(url, "_self");
  };

  toggleSelectionMedia = (selectedMediaId: string) => {
    let currentSelectedMediaIds = [...this.state.selectedMediaIds];
    const isAlreadySelected = currentSelectedMediaIds.includes(selectedMediaId);
    if (isAlreadySelected) {
      currentSelectedMediaIds = [...currentSelectedMediaIds].filter(
        (mediaId) => mediaId !== selectedMediaId
      );
    } else {
      currentSelectedMediaIds.push(selectedMediaId);
    }
    this.setState({ selectedMediaIds: currentSelectedMediaIds });
  };

  handleAcceptFiles = () => {
    const { tikTokMedia, selectedMediaIds } = this.state;
    const selectedMediaList = [...tikTokMedia].filter((media) =>
      selectedMediaIds.includes(media.id)
    );
    const uploadFiles = selectedMediaList.map((file) => {
      const { embed_link, id } = file;

      return {
        filePreview: embed_link,
        preview: embed_link,
        name: `TikTok_${id}`,
        type: getMediafileType(embed_link) || "",
      };
    });
    this.props.setFiles(uploadFiles);
  };

  getTikTokMedia = () => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTikTokMediaApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tikTokMediaApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getTikTokMediaApiCallId) {
      this.handleGetMediaAPIResponse(responseJson);
    }
  }

  handleGetMediaAPIResponse = (responseJson: any) => {
    if (responseJson.errors) {
      let errorMessage = responseJson.errors[0];
      if (errorMessage === "Account is not connected") {
        errorMessage = configJSON.tikTokAccountNotConnectedMessage;
        setTimeout(() => {
          this.initiateTikTokLogin();
        }, 3000);
      }
      this.setState({
        apiError: errorMessage || "Something went wrong",
        isLoading: false,
      });
    } else {
      this.setState({
        tikTokMedia: responseJson.data,
        isLoading: false,
      });
    }
  };
}
// Customizable Area End
