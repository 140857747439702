// Customizable Area Start
import React, { useRef, useState } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  Box,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { File } from "./types";
import { NewPostCreationSteps } from "./enums";
import { VolumeUpRounded, MoreHorizRounded } from "@material-ui/icons";
import {
  phoneStatusIcons,
  everyBrandLogo,
  avatarExample,
  heartIcon,
  message,
  arrowBack,
} from "./assets";
import DescriptionComponent from "../../../components/src/ReadMoreDescription.web";
import CustomButton from "../../../components/src/CustomButton.web";
import MediaComponent from "../../../components/src/MediaComponent.web";

SwiperCore.use([Navigation, Pagination]);

const styles = (theme: Theme) =>
  createStyles({
    newPostModalContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexBasis: "808px",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      padding: "30px 40px 40px",
      gap: "20px",
      borderRadius: "8px 8px 32px 8px",
      background: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "100%",
      },
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      width: "100%"
    },
    closeNewPostModal: {
      width: "24px",
      height: "24px",
      borderRadius: "50%",
      padding: 0,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },

    previewContainer: {
      position: "relative",
      borderRadius: "20px",
      overflow: "hidden",
      width: "100%",
      maxWidth: "376px"
    },

    swiperWrapper: {
      minWidth: "281px",
      maxWidth: "376px",
      width: "100%"
    },

    previewModalTitle: {
      fontWeight: 700,
      fontSize: "24px",
      fontFamily: "Inter, 'sans-serif'",
      letterSpacing: "-0.11px",
    },

    imagePreviewContainer: {
      display: "flex",
      height: "500px",
      minWidth: "281px",
      maxWidth: "376px",
      overflow: "hidden",
      backgroundColor: "#F2F2F2",

      "& > img": {
        objectFit: "cover",
      },

      "& > video": {
        width: "100%",
        objectFit: "cover",
      },
    },

    overlayGradiend: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "32%",
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 29.36%, #000 116.29%)"
    },

    previewTopOverlay: {
      position: "absolute",
      inset: 0,
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "16px",
      padding: "14px 0 2px",
      zIndex: 9,
      pointerEvents: "none"
    },

    overlayTop: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      padding: "0 25px",
      pointerEvents: "visible"
    },

    imagePreviewPhoneInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    imagePreviewPhoneTime: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "#FFF",
    },

    imagePreviewPhoneStatusIcons: {
      display: "flex",
      alignItems: "center",
    },

    imagePreviewSecondaryInfo: {
      position: "relative",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "center",

      "& > svg": {
        position: "absolute",
        right: 0,
        color: "#FFF",
      },
    },

    secondaryInfoTextContainer: {
      display: "flex",
      flexWrap: "nowrap",
      justifySelf: "center",
      gap: "10px"
    },

    secondaryInfoText: {
      fontSize: "10px",
      fontWeight: 900,
      textTransform: "uppercase",
      color: "#FFF",
    },

    paginationDots: {
      position: "absolute",
      bottom: "140px",
      left: "50%",
      zIndex: 10,
      pointerEvents: "visible",
      transform: "translateX(-50%)",
      "& .swiper-pagination": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        justifyContent: "center",
        "& .swiper-pagination-bullet": {
            height: "7px",
            width: "7px",
            backgroundColor: "rgba(255, 255, 255, 50%)",
            opacity: "1",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
        },
        "& .swiper-pagination-bullet-active": {
            backgroundColor: "#FFC629"
        },
      },
    },

    overlayBottom: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      pointerEvents: "visible"
    },

    postInfoContainer: {
      display: "flex",
      gap: "15px",
      justifyContent: "space-between",
      padding: "0px 25px 5px",
    },

    postTitleAndCaption: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      justifyContent: "flex-end",
    },

    postOverlayTitle: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFF",
    },

    postSocialStats: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
    },

    avatarContainer: {
      width: "35px",
      height: "35px",
      borderRadius: "50%",
      overflow: "hidden",
    },

    avatar: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },

    socialStatsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#FFF",
    },

    likesIcon: {
      width: "33px",
      height: "30px",
    },

    commentsIcon: {
      width: "23px",
      height: "22px",
      fill: "#FFF",
      filter: "invert(100%)",
      marginBottom: "1px"
    },

    everybrandLogoContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "6px",
      fontSize: "8px",
      fontWeight: 700,
      color: "#FFF",
      opacity: "0.8",
    },

    everybrandText: {
      fontSize: "9px",
      fontWeight: 700,
      opacity: "0.8",
      paddingTop: "5px"
    },

    videoProgressBarContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },

    videoProgressBar: {
      width: "90%",
      height: "3px",
      backgroundColor: "#FFF",
    },

    videoProgressBarStatus: {
      position: "relative",
      width: "30%",
      height: "100%",
      backgroundColor: "#FFC629",
    },

    videoProgressControl: {
      position: "absolute",
      top: "-2px",
      right: "0",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
    },

    buttonsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "14px",
    },

    stepButton: {
      width: "200px",
      height: "44px",
    },
  });

type Props = {
  classes: any;
  files: File[];
  cover: string;
  caption: string
  closeModal: () => void;
  setStep: (step: NewPostCreationSteps) => void;
  sendPost: () => void;
};

export const NewPostPreview = ({
  classes,
  files,
  caption,
  setStep,
  sendPost,
}: Props) => {

  const handleSlideChange = () => {
    const videos = document.querySelectorAll<HTMLVideoElement>(".swiper-slide video");
    videos.forEach((video) => {
      if (!video.paused) {
        video.pause();
        video.dispatchEvent(new CustomEvent("pauseVideoExternally"))
      }
    });
  }

  return (
    <Container
      id="newPostPreviewContainer"
      maxWidth={false}
      className={classes.newPostModalContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <Box className={classes.titleWrapper}>
        <IconButton
          id="closeNewPostPreviewModal"
          className={classes.closeNewPostModal}
          onClick={() => setStep(NewPostCreationSteps.COVER_SELECTION)}
        >
          <img src={arrowBack} alt="back button" />
        </IconButton>
        <Typography className={classes.previewModalTitle}>
          Post Preview
        </Typography>
      </Box>
        <Box className={classes.previewContainer}>
          <Box className={classes.swiperWrapper}>
            <Swiper
              data-test-id="preview-swiper"
              slidesPerView={1}
              pagination={{
                clickable: true,
                el: '.swiper-pagination',
              }}
              onSlideChange={handleSlideChange}
            >
              {files.map((fileInfo) => {
                const { id, preview, type } = fileInfo

                return (
                  <SwiperSlide key={id} slidesPerView={1}>
                    <Box className={classes.imagePreviewContainer}>
                      <MediaComponent url={preview} type={type} />
                    </Box>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Box>
          <Box className={classes.overlayGradiend} />

          <Box className={classes.paginationDots}>
            <Box className="swiper-pagination" />
          </Box>

          <Box className={classes.previewTopOverlay}>
            <Box className={classes.overlayTop}>
              <Box className={classes.imagePreviewPhoneInfo}>
                <Typography className={classes.imagePreviewPhoneTime}>
                  9:41
                </Typography>
                <img
                  className={classes.phoneStatusIcons}
                  src={phoneStatusIcons}
                  alt="phone status icons"
                />
              </Box>
              <Box className={classes.imagePreviewSecondaryInfo}>
                <Box className={classes.secondaryInfoTextContainer}>
                  <Typography className={classes.secondaryInfoText}>
                    Home
                  </Typography>
                  <Typography
                    className={classes.secondaryInfoText}
                    style={{ opacity: "0.3" }}
                  >
                    Trending
                  </Typography>
                </Box>

                <VolumeUpRounded />
              </Box>
            </Box>
            <Box className={classes.overlayBottom}>
              <Box className={classes.postInfoContainer}>
                <Box className={classes.postTitleAndCaption}>
                  <Typography className={classes.postOverlayTitle}>
                    Necronomicon ART
                  </Typography>
                  <DescriptionComponent description={caption} />
                </Box>
                <Box className={classes.postSocialStats}>
                  <Box className={classes.avatarContainer}>
                    <img
                      src={avatarExample}
                      alt="avatar"
                      className={classes.avatar}
                    />
                  </Box>
                  <Box className={classes.socialStatsContainer}>
                    <img
                      src={heartIcon}
                      alt="likes"
                      className={classes.likesIcon}
                    />
                    35 M
                  </Box>
                  <Box className={classes.socialStatsContainer}>
                    <img
                      src={message}
                      alt="comments"
                      className={classes.commentsIcon}
                    />
                    15 M
                  </Box>
                  <Box className={classes.socialStatsContainer}>
                    <MoreHorizRounded />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.everybrandLogoContainer}>
                <Typography className={classes.everybrandText}>
                  Powered by
                </Typography>
                <img
                  src={everyBrandLogo}
                  className={classes.everybrandLogo}
                  alt="everybrand"
                />
              </Box>
              <Box className={classes.videoProgressBarContainer}>
                <Box className={classes.videoProgressBar}>
                  <Box className={classes.videoProgressBarStatus}>
                    <Box className={classes.videoProgressControl} />
                  </Box>
                </Box>
              </Box>
            </Box>
        </Box>
        </Box>
      <Box className={classes.buttonsContainer}>
        <CustomButton
          id="confirmPostButton"
          className={classes.stepButton}
          onClick={sendPost}
        >
          Post
        </CustomButton>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(NewPostPreview);

// Customizable Area End
