// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import { PostTypes, ChoiceLetters } from "./enums";
import { Choice, NormalizedFile } from "./types";
import { everyBrandLogoFilled } from "./assets";

type Props = {
  classes: any;
  closeModal: () => void;
  postPreview: string;
  selectedFile: NormalizedFile;
  videoPreviewRef: React.RefObject<HTMLVideoElement>;
  postType: PostTypes;
  choices: Choice[];
  postSocialScrollText: string;
  postEveryreelText: string;
  runPostFunction: () => void;
};

const styles = (theme: Theme) =>
  createStyles({
    postPreview: {
      display: "flex",
      flexDirection: "column",
      gap: "45px",
      justifyContent: "space-between",
    },

    previewTitle: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },

    imagePreview: {
      position: "relative",
      display: "flex",
      maxWidth: "337px",
      minWidth: "300px",
      height: "500px",
      borderRadius: "20px",
      overflow: "hidden",
      background: "#F2F2F2",

      "& > img": {
        width: "100%",
        objectFit: "cover",
      },

      "& > video": {
        width: "100%",
      },
    },

    choicesPreview: {
      position: "absolute",
      bottom: "30px",
      left: 0,
      display: "flex",
      flexDirection: "column",
      gap: "7px",
      width: "100%",
      padding: "0 17px",
    },

    captionPreview: {
      marginBottom: "7px",
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "30px",
    },

    socialScrollPreviewOverlay: {
      position: "absolute",
      bottom: "30px",
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
      width: "100%",
      padding: "0 20px",
    },

    socialScrollCaption: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
      height: "19px",
      marginBottom: "24px",
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "30px",
    },

    everybrandLogoContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      gap: 0,
      fontSize: "8px",
      fontWeight: 700,
      color: "#FFF",
    },

    everybrandText: {
      paddingTop: "3px",
      fontSize: "8px",
      fontWeight: 700,
      opacity: "0.5"
    },

    linkPreview: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      height: "40px",
      borderRadius: "40px",
      backgroundColor: "rgba(255, 255, 255, 0.60)",
      color: "#000",
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Inter, 'sans-serif'"
    },

    linksContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },

    choicePreviewItem: {
      display: "flex",
      padding: "0 4px",
      alignItems: "center",
      gap: "12px",
      height: "44px",
      borderRadius: "30px",
      backgroundColor: "rgba(0, 0, 0, 0.50)",
    },

    choiceName: {
      color: "#FFF",
      fontSize: "14px",
      lineHeight: "18px", 
      fontWeight: 700,
      letterSpacing: "-0,12px",
    },

    choiceLetter: {
      flexShrink: 0,
      width: "37px",
      height: "37px",
      borderRadius: "50%",
      backgroundColor: "#FFC629",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "40px",  
      textAlign: "center"
    },

    postButton: {
      width: "100%",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      height: "56px",
      borderRadius: "30px",
      backgroundColor: "#FFC629",
      "&:hover": {
        backgroundColor: "#eebe40",
      }
    },
  });

export const CreateEveryreelSettingsPostPreview = ({
  classes,
  postPreview,
  selectedFile,
  videoPreviewRef,
  postType,
  choices,
  postSocialScrollText,
  postEveryreelText,
  runPostFunction
}: Props) => {
  return (
    <Box className={classes.postPreview}>
      <Typography className={classes.previewTitle}>{postPreview}</Typography>
      <Box className={classes.imagePreview}>
        {selectedFile.type.includes("image") ? (
          <img src={selectedFile.meta.preview} alt="image preview" />
        ) : (
          <video controls={false} ref={videoPreviewRef}>
            <source src={selectedFile.meta.preview} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}

        {postType === PostTypes.INTERACTIVE_FUNNEL && (
          <Box className={classes.choicesPreview}>
            <Typography className={classes.captionPreview}>
              {selectedFile.caption}
            </Typography>
            <Box className={classes.linksContainer}>
              {choices.map((choice, i) => (
                <Box key={String(i)} className={classes.choicePreviewItem}>
                  <Box className={classes.choiceLetter}>{ChoiceLetters[i]}</Box>
                  <Typography className={classes.choiceName}>
                    {choice.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.everybrandLogoContainer}>
              <Typography className={classes.everybrandText}>Powered by</Typography>
              <img
                src={everyBrandLogoFilled}
                className={classes.everybrandLogo}
                alt="everybrand"
              />
            </Box>
          </Box>
        )}

        {postType === PostTypes.SOCIAL_SCROLL && (
          <Box className={classes.socialScrollPreviewOverlay}>
            <Typography className={classes.socialScrollCaption}>
              {selectedFile.caption}
            </Typography>

            {selectedFile.isLinkAllowed && (
              <Box className={classes.linkPreview}>{selectedFile.linkText}</Box>
            )}

            <Box className={classes.everybrandLogoContainer}>
              <Typography className={classes.everybrandText}>Powered by</Typography>
              <img
                src={everyBrandLogoFilled}
                className={classes.everybrandLogo}
                alt="everybrand"
              />
            </Box>
          

          
          </Box>
        )}
      </Box>
      <Button id="postEveryreelButton" className={classes.postButton} onClick={runPostFunction}>
        {postType === PostTypes.SOCIAL_SCROLL
          ? postSocialScrollText
          : postEveryreelText}
      </Button>
    </Box>
  );
};

export default withStyles(styles)(CreateEveryreelSettingsPostPreview);
// Customizable Area End
