import * as React from 'react';
import {OutlinedInput, MenuItem, FormControl, Select, Box, styled} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    formControl: {
      width: 300, // Set width of the FormControl
    },
    select: {
      height: 44,
      width: 300
    },
  });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
    onChange: (event: React.ChangeEvent<{ value: unknown; }>) => void;
    selectedPlatforms: string[];

}

const platforms = ["linkedin", "X"];

const MultiSelectPlatform: React.FC<Props> = ({onChange, selectedPlatforms}) => {
    const classes = useStyles();

  return (
    <div>
        <FormControl className={classes.formControl}>
        <MuiContainer>
            <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={selectedPlatforms}
            onChange={onChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            className={classes.select}
            >
            {platforms.map((platform) => (
                <MenuItem key={platform} value={platform}>
                {platform}
                </MenuItem>
            ))}
            </Select>
        </MuiContainer>

        </FormControl>

    </div>
  );
}

export default MultiSelectPlatform;

const MuiContainer = styled(Box)({
    "select":{
        width: "280px",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Inter, 'sans-serif'",
        "& > div:nth-child(2)": {
        boxShadow: "inset 0px 1px 4px rgba(0,0,0,0.25)",
        border: "none",
        padding: "3px"
    }}
})