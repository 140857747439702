// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CustomLoader from "./CustomLoader.web";
import CustomButton from "./CustomButton.web";
const configJSON = require("./config.js");

const CustomDialogTitle = styled(DialogTitle)({
  position: "relative",
  padding: "40px 30px 20px",
});

const CloseIcon = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

const MainHeading = styled(Typography)({
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.13px",
});

const MediaWrapper = styled(Box)({
  padding: "0px 30px",
});

const ErrorMessageWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "20vh",
  alignItems: "center",
  padding: "0px 30px",
  "& > p": {
    fontSize: "14px",
    fontFamily: "Inter, 'sans-serif'",
    color: "#f44336",
  },
});

const ButtonsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "10px",
  padding: "20px 30px",
  "& button": {
    maxWidth: "132px",
    height: "44px",
  },
});

interface IProps {
  error: string;
  heading: string
  isLoading: boolean;
  children: React.ReactNode;
  selectedMediaIds: string[];
  handleGoBack: () => void;
  handleAcceptFiles: () => void;
}

class CustomLibraryWrapper extends Component<IProps> {
  render() {
    const {
      error,
      heading,
      children,
      isLoading,
      selectedMediaIds,
      handleGoBack,
      handleAcceptFiles,
    } = this.props;
    return (
      <>
        <CustomDialogTitle>
          <CloseIcon onClick={handleGoBack}>
            <CloseRounded />
          </CloseIcon>
          <MainHeading>{heading}</MainHeading>
        </CustomDialogTitle>
        <DialogContent>
          <CustomLoader
            isLoading={isLoading}
            wrapperHeight="55vh"
            loaderSize={24}
          >
            <ErrorMessageWrapper hidden={!error}>
              <Typography data-test-id="error-message">{error}</Typography>
            </ErrorMessageWrapper>
            <MediaWrapper>{children}</MediaWrapper>
          </CustomLoader>
        </DialogContent>
        <DialogActions>
          <ButtonsWrapper>
            <CustomButton btnType="secondary" onClick={handleGoBack}>
              {configJSON.cancelLabel}
            </CustomButton>
            <CustomButton
              data-test-id="accept-button"
              disabled={selectedMediaIds.length === 0}
              onClick={handleAcceptFiles}
            >
              {configJSON.acceptLabel}
            </CustomButton>
          </ButtonsWrapper>
        </DialogActions>
      </>
    );
  }
}

export default CustomLibraryWrapper;
// Customizable Area End
