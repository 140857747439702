import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getToken } from '../../../components/src/AuthService';

interface SocialLink {
  data: SocialcalLinkData
}

interface SocialcalLinkData {
  "id": string,
  "type": string,
  "attributes": SocialLinkAttributes
}
interface SocialLinkAttributes {
  "name": string,
  "url": string,
  "account_id": number,
  "social_app": boolean,
  "teammate": string
}

interface FacebookResponse {
  status: string;
  authResponse?: {
    accessToken: string;
    userID: string;
  };
}

export interface FBAuthResponse {
  accessToken: string;
  expiresIn: number;
  reauthorize_required_in: number;
  signedRequest: string;
  userID: string;
}
 
export interface FBResponse {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse?: FBAuthResponse;
}

declare global {
  export interface Window {
    fbAsyncInit: () => void;
    FB: typeof FB;
  }
 
  export const FB: {
    init: (config: {
      appId: string;
      cookie: boolean;
      xfbml: boolean;
      version: string;
    }) => void;
    AppEvents: {
      logPageView: () => void;
    };
    getLoginStatus: (callback: (response: FBResponse) => void) => void;
    login: (
      callback: (response: FBResponse) => void,
      options?: { scope: string }
    ) => void;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open: string | null;
  username: string;
  link: string;
  formList: { username: string, link: string }[];
  usernameErr: string;
  linkErr: string;
  popupType: string;
  socialLinkId: string;
  listData: any
  activePopupListItem: string;
  // popupListData: Array<string>
  isSnackbarTrue: boolean;
  socialLinkData: object;
  isToken: string;
  defaultValuesList:any;
  resetindex:number
  isShowAlert: boolean;
  showSucess:string;
  authStartPlatformName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SocialIntegrationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updataSocialLinkAPICallId: string = ""
  getSocialAccountsId: string=""
  getDefaultValuesListId: string=""
  socialMediaIntergrate: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open: null,
      username: "",
      link: "",
      formList: [],
      usernameErr: "",
      linkErr: "",
      popupType: "",
      socialLinkId: "",
      listData: [],
      // popupListData: [
      //   "Edit Social",
      //   "Reset"
      // ],
      activePopupListItem: "",
      isSnackbarTrue: false,
      socialLinkData: {},
      isToken: "",
      defaultValuesList:[],
      resetindex:0,
      isShowAlert: false,
      showSucess:"",
      authStartPlatformName: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    runEngine.debugLog("API Message Recived", message);
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.updataSocialLinkAPICallId) {
        this.setState({ isShowAlert: true,showSucess:"Changes Saved Successfully" }, () => {
          this.isShowCell()
          this.updateSocialLinkSuccessCallBack(responseJson);
        this.getSocialAccountsLIst(this.state.isToken)
        this.closeModal()
        })
        
      }
    }
    if (
      responseJson && responseJson.data && apiRequestCallId === this.getSocialAccountsId
    ) {
      this.setState({ listData:responseJson.data})
    
    }
    if (responseJson && apiRequestCallId === this.getDefaultValuesListId) {
      this.setState({ defaultValuesList:responseJson})
    }
    if(responseJson && apiRequestCallId === this.socialMediaIntergrate){
      this.setState({isShowAlert: true, showSucess: "platform connected"});
    }
    // Customizable Area End
  }


  // Customizable Area Start
  isShowCell = () => {
    setTimeout(() => this.setState({ isShowAlert: false }), 3000);
  }
  async componentDidMount() {
    let token = getToken();
    this.setState({ isToken: token })
    this.getSocialAccountsLIst(token)
    this.getDefaultValuesOfSocialAccountList(token)

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
        const url = new URL(window.location.href);
        url.searchParams.delete('state');
        url.searchParams.delete('code');
        url.searchParams.delete('scope');
        window.history.replaceState({}, '', url.toString());

        this.socialMediaIntergrate = await this.apiCall({
          contentType: configJSON.contentType,
          method: configJSON.postApiMethod,
          endPoint: configJSON.socialIntegrationsEndPoint,
          body: { type: 'linkedin', code: code }
        });
    }

  }

  onUsernameChange = (event: any) => {
    this.setState({
      username: event.target.value
    }, () => {
      const stringRegex = /^[A-Za-z ']+$/;
      if (stringRegex.test(this.state.username)) {
        this.setState({
          usernameErr: '',
        })
      } else {
        this.setState({
          usernameErr: this.state.username ? configJSON.usernameError : configJSON.blankError,
        })
      }
    }
    )
  }

  onLinkChange = (event: any) => {
    this.setState({
      link: event.target.value
    }, () => {
      const regex = /^.+$/;
      if (regex.test(this.state.link)) {
        this.setState({
          linkErr: ''
        }
        )
      } else {
        this.setState({
          linkErr: configJSON.blankError
        })
      }
    })
  }

  onSubmit = (event: any) => {
    event.preventDefault();
    const { username, link, formList } = this.state;
    const { usernameErr, linkErr } = this.state;
    const data = { username, link };

    if (!username && !link) {
      this.setState({
        usernameErr: configJSON.blankError,
        linkErr: configJSON.blankError
      });
    } else {
      this.setState({
        usernameErr: !username ? configJSON.blankError : "",
        linkErr: !link ? configJSON.blankError : "",
      });

      if (!usernameErr && !linkErr) {
        this.updateSocialLink()
        this.setState(
          {
            formList: [data, ...formList],
            username: '',
            link: '',
            usernameErr: '',
            linkErr: '',
          },
          () => {
            this.closeModal();
          }
        );
      }
    }
  }

  closeModal = () => {
    this.setState({
      open: null,
      usernameErr: "",
      link: ""
    })
  }

  // openModal = (listItem: string, item: any,index : number) => { 

       
  //     this.setState({
  //     open: listItem,
  //     username: item?.attributes?.name ?? item.name,
  //     link: item?.attributes?.url ?? item.url,
  //     // activePopupListItem: listItem,
  //     popupType: "",
  //     socialLinkId: item.id

  //   })
  // }
  openModal = (listItem: string, item: any, index: number) => {
    if (item) {  // Add this check to handle the case where item is undefined or null
      const username = item.attributes?.name ?? item.name;
      const link = item.attributes?.url ?? item.url;
  
      this.setState({
        open: listItem,
        username: username,
        link: link,
        popupType: "",
        socialLinkId: item.id
      });
    }
  };

  popupHandler = (popupType: string) => {
    this.setState({
      popupType
    })
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: getToken(),
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  updateSocialLink = async () => {
    
    let socialLinkBody = {
      name: this.state.username,
      url: this.state.link,
      social_app: true
    }
    this.updataSocialLinkAPICallId = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.putApiMethod,
      endPoint: `${configJSON.updateSocialLinkEndPoint}${this.state.socialLinkId}`,
      body: socialLinkBody
    });
  };

  updateSocialLinkSuccessCallBack = (responseJson: SocialLink) => {
    this.setState({ socialLinkData: responseJson })
  }

  getSocialAccountsLIst = async (token:string) => {
    
    const header = {
      'Content-Type': configJSON.contentType,
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getSocialAccountsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSocialLinkEndPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getDefaultValuesOfSocialAccountList = async (token:string) => {
    
    const header = {
      'Content-Type': configJSON.contentType,
      token: token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getDefaultValuesListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultSocialIntegrationValuesEndPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    )
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  startAuth = (index: number) => {
    let platformNames = ["youtube", "linkedin", "tiktok", "twitter", "instagram", "facebook"];
    let authUrls = [
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=46687415570-89o6gfd7c4n1m0uj19fpvog7buof1fb5.apps.googleusercontent.com&redirect_uri=https://localhost:3000/settings&response_type=code&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=STATE&access_type=offline",
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78h2k41uljsjg3&redirect_uri=${window.location.href}&scope=openid email profile w_member_social`,
      "",
      "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=LVA5ejdiRjFMN1dreWFMTlVPcEE6MTpjaQ&redirect_uri=https://localhost:3000/settings&scope=tweet.read%20users.read%20follows.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain",
      "",
      ""
    ]
    this.setState({authStartPlatformName: platformNames[index]});
    window.location.href = authUrls[index].toString();
  }

  // Customizable Area End
}
