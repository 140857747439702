// Customizable Area Start
import React from "react";
import {
  Button,
  Container,
  Typography,
  Card,
  Grid,
  Box,
  Divider,
  Snackbar,
  Modal,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextareaAutosize,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import UpdatesubscriptionController, { configJSON } from "./UpdatesubscriptionController.web";
import { withStyles, createStyles, Theme, styled } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Pricing } from "../../../components/src/SubscriptionsPrice.web";
import { EverybrandIcon, infoIcon, radioButtonOff, radioButtonOn } from "./assets";
import { pauseSubscriptionReasons } from "./constants";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomLoader from "../../../components/src/CustomLoader.web";

const CustomTextArea = styled(TextareaAutosize)({
  border: "1px solid #64748B",
  borderRadius: "1px",
  lineHeight: "24px",
  fontSize: "16px",
  fontWeight: 400,
  padding: "10px",
  color: "#0F172A"
})

export class Updatesubscription extends UpdatesubscriptionController {
  getPlanInterval(interval: string) {
    return interval === "year" ? "Yearly" : interval
  }

  renderTextArea(reason: string) {
    if (reason === "other") {
      return (
      <CustomTextArea
        minRows={5}
        data-test-id="textAreaField"
        placeholder="Please explain further (required)"
        onChange={this.handleOtherReason}
    />)}
    return ""
  }
  render() {
    const { classes }: any = this.props;
    const {
        plans,
        subscriptionModal,
        selectedPlan,
        pauseModal,
        pauseReason,
        activeFilter,
        showSuccessAlert,
        alertMessage,
        currentPlan,
        reasonError,
        pauseConfirmationDialog,
        planExpiryDate,
        isGettingPlans
      } = this.state

    return (
      <Container maxWidth={false} className={classes.subscriptionMainContainer}>
        <Modal
          open={subscriptionModal}
          onClose={this.closeFormModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          data-test-id="webUpdateModal"
        >
          <div
            className={classes.modalBox}>
            <Typography className={classes.infotextS2} style={{textAlign:"center"}}>
              Are you sure you want to update subscription?
            </Typography>
            <Button
              onClick={() => { this.updateBuPlan(selectedPlan) }}
              className={classes.modalBtn2}
            >
              Yes
            </Button>
            <Button
              data-test-id="No_button"
              onClick={() => this.closeFormModal()}
              className={classes.modalBtnDark2}
            >
              No
            </Button>
          </div>
        </Modal>
        <Dialog
          open={pauseModal}
          onClose={this.closePauseModal}
          data-test-id="webUpdateModal"
          classes={{
            paper: classes.pauseModalPaper
          }}
        >
          <DialogContent>
            <Typography className={classes.pauseReasonHeading}>
              Why do you want to pause your subscription?
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                data-test-id="radioOnChange"
                value={pauseReason}
                onChange={(e) => this.handlePauseReasonChange(e.target.value)}
              >
                {pauseSubscriptionReasons.map((reason) => {
                  return <FormControlLabel
                    key={reason}
                    value={reason}
                    control={<Radio style={{ color: "#000" }} />}
                    label={reason}
                  />
                })}
                <FormControlLabel
                  value="other"
                  control={<Radio style={{ color: "#000" }} />}
                  label="Other."
                />

              </RadioGroup>
              {this.renderTextArea(pauseReason)}
            </FormControl>
            <Box className={classes.errorWrapper} hidden={!reasonError}>
              <Typography data-test-id="reason-error">Please provide a reason</Typography>
            </Box>
            <Box className={classes.pauseModalBtn}>
              <CustomButton
                data-test-id="pauseAccountBtn"
                className={classes.pauseActionButton}
                onClick={this.handlePauseSubscription}
              >
                Continue
              </CustomButton>
              <CustomButton
                btnType="secondary"
                data-test-id="closePauseModalBtn"
                onClick={() => this.closePauseModal()}
                className={classes.pauseActionButton}
              >
                Cancel
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={pauseConfirmationDialog}
          onClose={this.closePauseConfirmationDialog}
          classes={{
            paper: classes.pauseConfirmationPaper
          }}
        >
          <DialogContent>
            <Typography data-test-id="plan-expiry-info" className={classes.pauseConfirmationText}>
              Your Subscription will be paused on {planExpiryDate}.
            </Typography>
            <Box className={classes.confirmationButtonsWrapper}>
              <CustomButton btnType="secondary" onClick={this.closePauseConfirmationDialog}>
                Cancel
              </CustomButton>
              <CustomButton onClick={this.pauseBuAccout}>
                Confirm
              </CustomButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            data-test-id="webUpdateAlert"
            elevation={6}
            variant="filled"
            severity={"success"}
            className={classes.snackbarWrapper}
          >
            {alertMessage}
          </MuiAlert>
        </Snackbar>
        <Box className={classes.everyBrandBox}>
          <Typography
            className={classes.webHeadingSus}
            data-test-id="webHeadingSus"
          >
            {configJSON.updateSubscriptionText}
          </Typography>
          <img src={EverybrandIcon} alt="" />
        </Box>
        <CustomLoader
          wrapperHeight="60vh"
          loaderSize={28}
          isLoading={isGettingPlans}
        >
        <Box className={classes.buttonsBox}>
          <Button
            data-test-id="annualButton"
            className={
              activeFilter === "year"
                ? classes.activeSelecetBtn
                : classes.selectBtns
            }
            startIcon={
              activeFilter === "year" ? (
                <img src={radioButtonOn} alt=""/>
              ) : (
                <img src={radioButtonOff} alt=""/>
              )
            }
            onClick={() => this.handleFilter("year")}
          >
            {configJSON.annualPricingLabel}
          </Button>
          <Divider orientation="vertical" className={classes.btnDivider} />{" "}
          <Button
            data-test-id="monthlyButton"
            className={
              activeFilter === "month"
                ? classes.activeSelecetBtn
                : classes.selectBtns
            }
            startIcon={
              activeFilter === "month" ? (
                <img src={radioButtonOn} alt=""/>
              ) : (
                <img src={radioButtonOff} alt=""/>
              )
            }
            onClick={() => this.handleFilter("month")}
          >
            {configJSON.monthlyPricingLabel}
          </Button>
        </Box>
        <Box className={classes.plansWrapper}>
          {plans.map((x: any) => (
              <Card
                key={x.id}
                data-test-id="plansCard"
                className={
                  x.attributes.plans[0].id === currentPlan
                    ? classes.activePlanCard
                    : classes.plansCard
                }
              >
                <Typography className={classes.webHeading}>
                  {x.attributes.name}
                </Typography>
                <Box sx={{ ...commonCss.dFlex }}>
                  <FiberManualRecordIcon style={{ ...commonCss.bulletSmall }} />
                  <Typography>
                    <span style={{ ...commonCss.spantag }}>
                      {x.attributes.description.split(":")[0]}
                      {": "}
                    </span>{" "}
                    {x.attributes.description.split(":")[1]}
                  </Typography>
                </Box>
                <Pricing
                  currency={x.attributes.plans[0].currency}
                  amount={x.isPlatformManagement ? (parseFloat(x.attributes.plans[0].price) + 1000).toString() + ".0" : x.attributes.plans[0].price}
                  interval={this.getPlanInterval(x.attributes.plans[0].interval)}
                />
                <Typography className={classes.cardPara}>
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes?.service?.max_contacts_count}
                  </span>{" "}
                  contacts
                </Typography>
                <Typography className={classes.cardPara}>
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes.service?.max_members_count}
                  </span>{" "}
                  team members
                </Typography>
                <Typography className={classes.cardPara}>
                  {" "}
                  <span style={{ ...commonCss.spantag }}>
                    {x.attributes.name === "Essentials"
                      ? "Includes"
                      : "Everything in Essential, plus:"}
                  </span>
                </Typography>
                {x.attributes.service?.permissions.map((item: string) => (
                  <Box key={item} sx={{ ...commonCss.dFlex }}>
                    <FiberManualRecordIcon
                      style={{ ...commonCss.bulletSmall }}
                    />
                    <Typography className={classes.cardPara}>{item}</Typography>
                  </Box>
                ))}
                {x.attributes.name === "Essentials" ? null : (
                  <Box
                    sx={{ ...commonCss.dFlex, justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        ...commonCss.dFlex,
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={infoIcon} alt="" className={classes.infoIcon} />
                      <Typography className={classes.cardPara}>
                        Add platform <br />
                        management
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => this.platformToggling(x.attributes.id)}
                      className={
                        x.isPlatformManagement
                          ? classes.activeSwitch
                          : classes.inActiveSwitch
                      }
                    >
                      <FiberManualRecordIcon style={{ color: "#FFF" }} />
                    </Box>
                  </Box>
                )}
                <CustomButton
                  onClick={() => {
                    this.setState({ selectedPlan: x })
                    this.openFormModal()
                  }}
                  variant="contained"
                  className={classes.selectBtnBottom}
                  data-test-id="selectBTN"
                >
                  Select
                </CustomButton>
              </Card>
          ))}
        </Box>
        <Card
          data-test-id="plansCard"
          className={classes.pauseCard}
        >
          <Box flex={1} sx={{ width: { sm: "100%", md: "auto" } }}>
            <Typography className={classes.webHeading}>
              {configJSON.pauseMySubscriptionText}
            </Typography>
            <Box sx={{ ...commonCss.dFlex }}>
              <FiberManualRecordIcon style={{ ...commonCss.bulletSmall }} />
              <Typography style={{ ...commonCss.greyText }}>
                <span style={{ ...commonCss.greyText, ...commonCss.boldText }}>
                  Considering a break
                  {"? "}
                </span>{" "}
                Pause your subscription. Pausing allows you to retain your hard work, reviews, and posts on your website. It also preserves all your valuable customer conversations and provides you with the flexibility to return without losing your current plan. Feel free to contact us to pause or cancel your account. We're here to assist you!
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: { sm: "100%", md: "auto" }}}>
            <Pricing
              currency={this.state.pausedAccount?.attributes?.plans[0]?.currency}
              amount={this.state.pausedAccount?.attributes?.plans[0]?.price}
              interval={this.getPlanInterval(this.state.pausedAccount?.attributes?.plans[0]?.interval)}
            />
            <CustomButton
              className={classes.textUsBottom}
              data-test-id="pause-subscription-button"
              onClick={this.openPauseModal}
            >
              Text Us
            </CustomButton>
          </Box>
        </Card>
        </CustomLoader>
      </Container>
    );
  }
}

export const commonCss: any = {
  activeSwitch: {
    backgroundColor: "#FFC629",
    width: "47px",
    height: "25px",
    borderRadius: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    cursor: "pointer",
    transition: "background-color 0.8s ease, justify-content 0.8s ease",
  },
  boldText: {
    fontWeight: 700,
  },
  greyText: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Inter, 'sans-serif'",
    lineHeight: "19.5px"
  },
  inActiveSwitch: {
    backgroundColor: "#ABB0BC",
    width: "47px",
    height: "25px",
    borderRadius: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    cursor: "pointer",
    transition: "background-color 0.8s ease, justify-content 0.8s ease",
  },
  spantag: {
    fontWeight: "bold",
    color: "#475569",
    textTransform: "capitalize",
  },
  bulletSmall: {
    fontSize: "8px",
    margin: "4px 5px 0 0",
    color: "#475569",
  },
  cardPara: {
    textTransform: "capitalize",
  },
  greyColor: {
    color: "#475569",
  },
  button: {
    backgroundColor: "#FFC629",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "5px 30px",
  },
  btnsSelect: {
    color: "#0F172A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "20px",
  },
  dFlex: {
    display: "flex",
    flexDirection: "row",
  },
  plansCard: {
    borderRadius: "20px",
    background: "#FFF",
    padding: "20px 30px",
    margin: "10px",
    height: "650px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "none",
    minWidth: "300px",
    flex: 1,
    maxWidth: "400px"
  },
};
const styles = (theme: Theme) =>
  createStyles({
    infoIcon: {
      height: "18px",
      width: "18px",
      margin: "5px 5px 0 0"
    },
    plansWrapper: {
      display: "flex",
      gap: "15px",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    snackbarWrapper: {
      backgroundColor: "#000"
    },
    everyBrandBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    webHeading: {
      color: "#0F172A",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      fontFamily: "Inter, 'sans-serif'",
      marginBottom: "10px"
    },
    activeSwitch: {
      backgroundColor: "#FFC629",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      cursor: "pointer",
    },
    modalBtn2: {
      ...commonCss.button,
      padding: "20px 80px",
      width: "100%",
      margin: "10px 0",
    },
    pauseActionButton: {
      width: "100%",
      maxWidth: "360px",
      margin: "5px 0",
      height: "56px"
    },
    modalBtnDark2: {
      ...commonCss.button,
      backgroundColor: "#000",
      color: "#FFFFFF",
      padding: "20px 80px",
      margin: "10px 0",
      width: "100%",
      maxWidth: "360px",
      "&:hover": {
        color: "#000",
      },
    },
    inActiveSwitch: {
      backgroundColor: "#ABB0BC",
      width: "47px",
      height: "25px",
      borderRadius: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      cursor: "pointer",
    },
    selectBtnBottom: {
      height: "44px",
      "& span": {
        color: "#0F172A",
      }
    },
    textUsBottom: {
      width: "100%",
      height: "44px",
      "& span": {
        color: "#0F172A",
      }
    },
    cardParaSubTextStyle: {
      fontWeight: "bold",
      letterSpacing: "0.075em",
    },
    cardParaStyle: {
      textTransform: "capitalize",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
    },
    cardParaSpanStyle: {
      fontSize: "14px",
      verticalAlign: "super",
    },
    cardParas: {
      ...commonCss.greyColor,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "19.5px",
      padding: "20px 0",
    },
    plansCard: {
      ...commonCss.plansCard,
      border: "1px solid #ABB0BC"
    },
    pauseCard: {
      borderRadius: "20px",
      background: "#FFF",
      padding: "20px 30px",
      margin: "10px",
      display: "flex",
      gap: "25px",
      alignItems: "flex-start",
      justifyContent: "space-between",
      border: "1px solid #ABB0BC",
      boxShadow: "none",
      flexWrap: "wrap",
    },
    activePlanCard: {
      ...commonCss.plansCard,
      border: "6px solid #000",
    },
    btnDivider: {
      height: "auto",
      alignSelf: "stretch",
      border: "1px solid #C0C4CD",
      margin: "0 15px",
    },
    buttonsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      margin: "20px 0",
    },
    selectBtns: {
      ...commonCss.btnsSelect,
    },
    activeSelecetBtn: {
      ...commonCss.btnsSelect,
      borderRadius: "5px",
      backgroundColor: "#D9D9D9",
    },
    teamParaBtnB: {
      color: "#64748B",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "18px",
    },
    subscriptionMainContainer: {
      padding: "40px",
      "& *": {
        fontFamily: "Inter, 'sans-serif'"
      }
    },
    modalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "32px 48px 24px",
      width: "450px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0px 0px 40px 0",
    },
    infotextS2: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
      padding: "30px 0",
    },
    pauseReasonHeading: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      padding: "20px 0 30px",
    },
    webHeadingSus: {
      color: "#000",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
    },
    cardHeadingTeam: {
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    cardParaTeam: {
      color: "#64748B",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    pauseModalPaper: {
      borderRadius: "0px 0px 50px 0",
      maxWidth: "841px",
      width: "100%",
      padding: "30px",
      "& *": {
        fontFamily: "Inter, 'sans-serif'"
      }
    },
    pauseConfirmationPaper: {
      borderRadius: "0px 0px 20px 0",
      maxWidth: "636px",
      width: "100%",
      padding: "30px",
      "& *": {
        fontFamily: "Inter, 'sans-serif'"
      }
    },
    pauseConfirmationText: {
      fontSize: "18px",
      fontWeight: 700,
      color: "#000"
    },
    confirmationButtonsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      marginTop: "60px",
      "& > button": {
        width: "100%",
        maxWidth: "232px",
        height: "56px"
      }
    },
    pauseModalBtn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    errorWrapper: {
      marginTop: "5px",
      "& p": {
        fontSize: "12px",
        color: "#ff0000"
      }
    }
  });

export default withStyles(styles)(Updatesubscription);
// Customizable Area End
