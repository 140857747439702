// Customizable Area Start
import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { ArrowBackIosRounded } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";

const BackButton = styled(IconButton)({
  height: "27px",
  width: "27px",
  position: "relative",
  "& svg": {
    color: "#000",
    position: "absolute",
    left: "7%",
    fontSize: "18px"
  },
});

interface IProps {
  dataTestId?: string;
  handleClick: (event: React.MouseEvent) => void;
}

export class CustomBackButton extends Component<IProps> {
  render() {
    const { dataTestId, handleClick } = this.props;

    return (
      <BackButton data-test-id={dataTestId} onClick={handleClick}>
        <ArrowBackIosRounded fontSize="small" />
      </BackButton>
    );
  }
}

export default CustomBackButton;
// Customizable Area End
