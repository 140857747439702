import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import TopActiveUsers from '../../dashboard/src/TopActiverUsers.web';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import UserProfileController from './UserProfileController.web';
import CustomLoader from '../../../components/src/CustomLoader.web';
import CustomButton from '../../../components/src/CustomButton.web';

export class EuuserProfile extends UserProfileController {
  render() {
    const { classes, navigation }: any = this.props;
    const { top6BrandProfile, profileDataEU } = this.state

    return (
      <div style={{background: "#f1f4f9"}}>
        <NavigationMenuEUWeb brands={this.state.brandsIFollow}/>
        {this.state.circularLoaderEu ? <CustomLoader loaderSize={24} isLoading={this.state.circularLoaderEu}/> : 
        <div className={classes.profileContentWrapper} >
          <Box paddingX={3}>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Box className={classes.ebEuProfileContainer} style={{ margin: '0 auto', padding: 2 }}>
                  <Box className={classes.imageOverlay}>
                    <img className={classes.image} src={this.state.profileDataEU.photo || ""} alt="Profile Slide 1" />
                  </Box>
                  {!this.state.userID && <Box className={classes.editIconContainer} data-test-id={"eu-profile-edit-btn"} onClick={()=>this.handleNavigateEditProfile()}>
                      <EditOutlinedIcon />
                  </Box>}
                  <Box className={classes.detailsContainer}>
                    <Typography variant="body2">{`${this.state.profileDataEU.city || ""}${this.state.profileDataEU.city ? "," : ""} ${this.state.profileDataEU.country || ""}`}</Typography>
                    <Typography variant="h3" style={{fontWeight: 600}}>{this.state.profileDataEU.name || ""}</Typography>
                    <Typography variant="body2">{this.state.profileDataEU.headline || ""}</Typography>
                    {this.state.userID && this.state.selfProfile === "false" && <Button variant="contained" data-test-id="user-follow-btn" color="primary" className={classes.followBtn} onClick={()=>this.followBrandApiProfile(this.state.userID, true, this.state.isFollowing)}>{this.state.isFollowing ? "Following" : "Follow"}</Button>}
                  </Box>
                  <Box className={classes.followContainer}>
                    <Box>
                      <Typography variant="h4" className={classes.fontWeight}>{this.state.profileDataEU.followers}</Typography>
                      <Typography variant="h6" className={classes.fontWeight}>Followers</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h4" className={classes.fontWeight}>{this.state.profileDataEU.following}</Typography>
                      <Typography variant="h6" className={classes.fontWeight}>Following</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                {profileDataEU.personality_prompt.length > 0 ?
                  <Box className={classes.ebSwiperBox} data-test-id="swiperContainer">
                  <Swiper
                      onSwiper={this.onSwipe}
                      onSlideChange={this.onSlideChange}
                      slidesPerView={1}
                      navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      }}
                    >
                      {profileDataEU.personality_prompt.map((presonalityPompt, index) => (
                        <SwiperSlide className={classes.ebSwiperSlideContainer}>
                        <Box sx={{ position: 'relative' }}>
                          <Box className={classes.sliderBottomContainer} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                          {index !== 0 && <IconButton onClick={this.handlePrevSlide} data-test-id={`prev-button${index}`} className={`swiper-button-prev`}>
                              </IconButton>}
                            <Box>
                            <Typography variant="h5" className={classes.subHeading1}>
                              {presonalityPompt.question}
                            </Typography>
                            <Typography variant="h5" className={classes.subHeading2}>
                              {presonalityPompt.answer}
                            </Typography>
                            </Box>
                            {index !== 2 && <IconButton onClick={this.handleNextSlide} data-test-id={`next-button${index}`} className={`swiper-button-next`}>
                            </IconButton>}
                          </Box>
                        </Box>
                      </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                  :
                  <Box className={classes.addPersonalityWrapper}>
                    <CustomButton onClick={this.handleNavigateEditProfile}>
                      Add Some Personality
                    </CustomButton>
                  </Box>
                }
                </Box>
                <Box>
                    <ul className={classes.slidesDotsUl}>
                    {this.state.profileDataEU.personality_prompt.map((presonalityPompt, index) => (<li className={index === this.state.currentSlide && classes.slidesDotsColor}>
                    </li>))}
                    </ul>
                  </Box>
                <Box style={{ padding: 2 }}>
                  <Typography variant='h5' className={classes.postsHeading}>Posts</Typography>
                  <Grid container spacing={2} key={"reelContainer"}>
                    {this.state.postsDataEU.map((reel, index) => (
                      <Grid item xs={4} key={reel.id}>
                        <Box style={{ position: 'relative', overflow: 'hidden', height: "330px" }}>
                          <img
                            src={reel.attributes.thumbnail || `https://picsum.photos/id/${index + 10}/200/300`}
                            alt={`Post ${reel.id}`}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <Box
                            style={{
                              position: 'absolute',
                              bottom: 15,
                              left: 15,
                              color: '#fff',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)',
                              padding: '2px 8px',
                              borderRadius: 1,
                            }}
                          >
                            <Typography variant="body2">
                              &#9654;
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <div>
                  <TopActiveUsers
                    navigation={navigation}
                    top6={top6BrandProfile}
                  />
                  <SuggestedBrands
                    brands={this.state.suggestedBrands}
                    followBrand={this.followBrandApiProfile}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>}
      </div>
    );
  }
}

const styles = (theme: Theme) => createStyles({
  profileContentWrapper: {
    margin: "15px auto",
    maxWidth: "1240px",
    width: "100vw"
  },
  ebSwiperBox: {
    position: "relative",
    height: "auto",
    backgroundColor: "#fff",
    "& .swiper-wrapper": {
      height: "auto"
    },
    width: "auto",
  },
  ebEuProfileContainer: {
    position: "relative",
    height: "auto",
    width: "auto",
    "& img": {
      width: "100%",
      height: "500px",
      objectFit: "cover"
    },
  },
  addPersonalityWrapper: {
    backgroundColor: "#fff",
    padding: "10px"
  },
  ebSwiperSlideContainer: {
    width: "auto",
    height: "auto",
    "& img": {
      width: "100%",
      height: "500px",
      objectFit: "cover"
    },
  },
  imageOverlay: {
    position:'relative',
    "&::before": {
      content: '""',
      display: 'block',
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 29.36%, rgba(0, 0, 0, 0.8) 116.29%)",
      position:'absolute',
      top:0,
      left:0,
      right:0,
      bottom:0
    }
  },
  image: {
    borderRadius: "20px 20px 0px 0px"
  },
  fontWeight: {
    fontWeight: 700
  },
  detailsContainer: {
    position: 'absolute', bottom: 35, left: 50,
    color: "#fff",
    display: "flex", 
    flexDirection: "column",
    gap:"5px"
  },
  editIconContainer: {
    "& svg": {
      position: 'absolute', top: 20, right: 25,
      color: "#fff",
      width: "32px",
      height: "32px",
      background: "#0F172A",
      borderRadius: "8px",
      textAlign: "center",
      padding: "5px"
    }
  },
  followBtn: {
    background:'#FFC629',
    color:'#000',
    fontWeight:700,fontSize:'16px',
    borderRadius: "50px",
    textTransform: "capitalize",
    padding: "5px 35px 0px 35px",
    marginTop: "10px",
    "&:hover": {
      background:'#FFC629',
      color:'#000',
    }
  },
  followContainer:{
    position: 'absolute', bottom: 35, right: 50,
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    gap:'20px',
    color: "#fff"
  },
  sliderBottomContainer: {
    padding: "20px",
    "& .swiper-button-next ": {
      border: "2px solid",
      padding: "22px",
    },
    "& .swiper-button-next::after": {
      fontSize:"24px",
    fontWeight: "600"
    },
    "& .swiper-button-prev": {
      border: "2px solid",
      padding: "22px",
    },
    "& .swiper-button-prev::after": {
      fontSize:"24px",
    fontWeight: "600"
    }
  },
  subHeading1: {
    fontWeight: 600,
    color: "#FFC629"
  },
  subHeading2: {
    fontWeight: 600,
    color: "#000"
  },
  slidesDotsUl: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    backgroundColor: "#fff",
    "& li": {
      margin: "0px 15px",
      fontSize: "32px",
    },
  },
  slidesDotsColor: {
    color: "#FFC629 !important"
  },
  postsHeading: {
    fontFamily: "Inter",
    // fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    // textAlign: "left",
    margin: "20px",
    color: "#0F172A"
  },
  swiperBtn: {
    border: "2px solid",
  padding: "22px",
  "&::after": {
    fontSize:"24px",
    fontWeight: "600"
  }
  },
});

export default withStyles(styles)(EuuserProfile);