// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import TikTokLibraryController from "./TikTokLibraryController.web";
import CustomLibraryWrapper from "../../../components/src/CustomLibraryWrapper.web";
const configJSON = require("./config.js")

const MediaContainer = styled(Box)({
  position: "relative",
  width: "100%",
  paddingTop: "100%",
  backgroundColor: "#000",
  overflow: "hidden",
  aspectRatio: 4 / 5,
  borderRadius: "8px",
  cursor: "pointer",
});

const SelectedLayer = styled(Box)({
  position: "absolute",
  height: "100%",
  width: "100%",
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "0px",
  pointerEvents: "none",
  "& span": {
    display: "flex",
    fontSize: "40px",
    color: "#eebe40",
    fontWeight: 700,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, 'sans-serif'",
  },
});

const MediaElement = styled("img")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const MediaVideo = styled("video")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

class TikTokLibrary extends TikTokLibraryController {
  render() {
    const { handleGoBack } = this.props;
    const { isLoading, selectedMediaIds, tikTokMedia, apiError } = this.state;

    return (
      <>
        <CustomLibraryWrapper
          error={apiError}
          isLoading={isLoading}
          selectedMediaIds={selectedMediaIds}
          heading={configJSON.selectTikTokPostTitle}
          handleGoBack={handleGoBack}
          handleAcceptFiles={this.handleAcceptFiles}
        >
          <Grid container spacing={2}>
            {tikTokMedia.map((media) => {
              const { id, embed_link } = media;
              const currentMediaIndex = selectedMediaIds.indexOf(id);

              return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={id}>
                  <MediaContainer
                    data-test-id="media-container"
                    onClick={() => this.toggleSelectionMedia(id)}
                  >
                    {/* {media_type === "IMAGE" ? (
                      <MediaElement src={media_url} alt="Instagram Media" />
                    ) : ( */}
                      <MediaVideo src={embed_link} controls />
                    {/* )} */}
                    {currentMediaIndex !== -1 && (
                      <SelectedLayer>
                        <span data-test-id="selected-post-count">
                          {currentMediaIndex + 1}
                        </span>
                      </SelectedLayer>
                    )}
                  </MediaContainer>
                </Grid>
              );
            })}
          </Grid>
        </CustomLibraryWrapper>
      </>
    );
  }
}

export default TikTokLibrary;
// Customizable Area End
