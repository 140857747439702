// Customizable Area Start
import { MouseEvent } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { ContactsListViews, InviteFriendsStepType } from "./enums";

export const configJSON = require("./config");
export interface Review {
  rating:number
}
export interface Post {
days_ago:string;
hours_ago	:string;
minutes_ago:string
}
export interface Contact {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: number;
    full_phone_number: string;
    waitlist_id: any | null;
    appointment_date: any | null;
    appointment_status: any | null;
    profile_pic_url: string | null;
    is_banned_user: boolean;
    is_opt_out_user: boolean;
    review?: Review;
    latest_post_details?:Post;
    message?:{error:string};
  };
}

export interface Account {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    country_code: string;
    phone_number: number;
    full_phone_number: string;
    profile_pic: {url : string} | null;
  };
}

export interface NormalizedContact {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  waitlist_id?: any;
  appointment_date?: any;
  appointment_status?: any;
  profile_pic_url?: string;
  is_banned_user: boolean;
  is_opt_out_user: boolean;
  isContactInfoOpened: boolean;
  isActionMenuOpened: boolean;
  review?:Review;
  post?:Post;
  message?:{error:string};
}

export interface Props {
  classes?: any;
}

interface S {
  contacts: Contact[];
  normalizedContacts: NormalizedContact[];
  filters: { name: string; checked: boolean }[];
  error: string;
  currentView: ContactsListViews;
  contactsFiles: any;
  acceptModal: boolean;
  contactToEdit?: {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
    fullPhoneNumber?: string;
  };
  activeFilter?: string;
  isLoading: boolean
}

interface SS {
  navigation: any;
  stepType: InviteFriendsStepType;
}

export default class ContactsListContainerController extends BlockComponent<
  Props,
  S,
  SS
> {
  contactsApiCallId: string = "";
  banContactApiCallId: string = "";
  sendFileApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    this.state = {
      contacts: [],
      normalizedContacts: [],
      filters: [
        { name: "All", checked: true },
        { name: "Banned Users", checked: false },
        { name: "Opt Outs", checked: false },
      ],
      error: "",
      currentView: ContactsListViews.LIST,
      contactsFiles: [],
      contactToEdit: undefined,
      acceptModal: false,
      activeFilter: "",
      isLoading: false
    };
  }

  allContacts = configJSON.allContacts;
  currentContacts = configJSON.currentContacts;
  viewProfile = configJSON.viewProfile;
  complianceInfo = configJSON.complianceInfo;
  editUser = configJSON.editUser;
  banUserText = configJSON.banUser;
  unbanUser = configJSON.unbanUser;
  approvePhotoAndVideo = configJSON.approvePhotoAndVideo;

  async componentDidMount() {
    this.fetchContacts();
    localStorage.getItem("addContact") == ContactsListViews.ADD_CONTACT && this.checkFilter("");
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): void {
    if (
      (this.getFilterName(prevState.filters) !==
        this.getFilterName(this.state.filters) &&
        this.state.filters.some((filter) => filter.checked)) ||
      (prevState.contactToEdit && !this.state.contactToEdit)
    ) {
      this.fetchContacts();
    }
  }

  getStyleByCondition = (condition: boolean, truthyStyle: string, falsyStyle: string) => {
    return condition ? truthyStyle : falsyStyle
  }

  setCurrentView = (view: ContactsListViews) => {
    this.setState({ currentView: view });
  };

  setContactsFiles = (files: any) => {
    this.setState({ contactsFiles: files, acceptModal: true });

  };

  getContactsByName = (name:string) => {
    return `Contact is ${name}`
  }

  deleteContactByName = (id:number) => {
    return id
  }

  showContact = (contactId: number) => {
    this.setState((prevState) => {
      return {
        normalizedContacts: prevState.normalizedContacts.map((contact) => {
          if (contact.id !== contactId) {
            return {
              ...contact,
              isContactInfoOpened: false,
            };
          } else {
            return {
              ...contact,
              isContactInfoOpened: !contact.isContactInfoOpened,
            };
          }
        }),
      };
    });
  };

  toggleContactMenu = (contactId?: number) => {
    this.setState((prevState) => {
      return {
        normalizedContacts: prevState.normalizedContacts.map((contact) => {
          if (contact.id !== contactId) {
            return {
              ...contact,
              isActionMenuOpened: false,
            };
          } else {
            return {
              ...contact,
              isActionMenuOpened: !contact.isContactInfoOpened,
            };
          }
        }),
      };
    });
  };

  normalizeContacts = (contacts: Contact[]): NormalizedContact[] => {
    return contacts.map((contact) => ({
      id: contact.attributes.id,
      firstName: contact.attributes.first_name,
      lastName: contact.attributes.last_name,
      fullName: `${contact.attributes.first_name} ${contact.attributes.last_name}`,
      phone: contact.attributes.full_phone_number,
      email: contact.attributes.email,
      waitlist_id: contact.attributes.waitlist_id || undefined,
      appointment_date: contact.attributes.appointment_date || undefined,
      appointment_status: contact.attributes.appointment_status || undefined,
      profile_pic_url: contact.attributes.profile_pic_url || undefined,
      is_banned_user: contact.attributes.is_banned_user,
      is_opt_out_user: contact.attributes.is_opt_out_user,
      isContactInfoOpened: false,
      isActionMenuOpened: false,
      review:contact.attributes?.review,
      post:contact.attributes.latest_post_details,
      message:contact.attributes.message,
    }));
  };

  normalizeAccount = (accounts: Account[]): NormalizedContact[] => {
    return accounts.map((account) => ({
      id: account.attributes.id,
      firstName: account.attributes.first_name,
      lastName: account.attributes.last_name,
      fullName: `${account.attributes.first_name} ${account.attributes.last_name}`,
      phone: account.attributes.full_phone_number,
      email: account.attributes.email,
      waitlist_id: undefined,
      appointment_date: undefined,
      appointment_status: undefined,
      profile_pic_url: account.attributes.profile_pic?.url,
      is_banned_user: true,
      is_opt_out_user: false,
      isContactInfoOpened: false,
      isActionMenuOpened: false,
    }));
  };

  checkFilter = (filterName: string) => {
    this.setState((prevState) => {
      const updatedFilters = prevState.filters.map((filter) => {
        if (filter.name === filterName) {
          return {
            ...filter,
            checked: true,
          };
        } else {
          return {
            ...filter,
            checked: false,
          };
        }
      });

      const currentView = filterName
        ? ContactsListViews.LIST
        : ContactsListViews.ADD_CONTACT;
      localStorage.setItem("addContact","")

      return { filters: updatedFilters, currentView, activeFilter: filterName };
    });
  };

  getFilterName = (filters: { name: string; checked: boolean }[]): string => {
    return filters.find((filter) => filter.checked)?.name || "";
  };
  getContactsName = (name:string) => {
      return name
  }

  handleAddButton = (id:string) => {
    return id
  }

  openEditContactModal = (contactData: {
    id: number;
    firstName: string;
    lastName: string;
    email?: string;
    fullPhoneNumber?: string;
  }) => {
    this.setState({ contactToEdit: contactData });
  };

  closeEditContactModal = (e: any) => {
    e?.stopPropagation();
    this.setState({ contactToEdit: undefined });
  };

  fetchContacts(): boolean {
    const requestHeaders = {
      "Content-Type": configJSON.inviteFriendApiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactsApiCallId = requestMessage.messageId;

    const currentFilterName = this.getFilterName(this.state.filters);
    const currentEndpoint =
      (currentFilterName === "All" && configJSON.contactsEndPoint) ||
      (currentFilterName === "Banned Users" &&
        configJSON.bannedContactsEndpoint) ||
      (currentFilterName === "Opt Outs" && configJSON.optOutContactEndpoint);

    this.setState({isLoading : true, normalizedContacts: []})

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      currentEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleBanStatus(userId: number, status: boolean): boolean {
    const requestHeaders = {
      "Content-Type": configJSON.inviteFriendApiContentType,
      token: getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.banContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        contact: {
          is_banned_user: status,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.contactsEndPoint}/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeaders)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  sendContactsFile = () => {
    if (!this.state.contactsFiles.length) return;

    const formData = new FormData();

    formData.append("files", this.state.contactsFiles[0]);

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendFileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: getToken(),
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/contacts/import_contacts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleModalClose = () => {
    this.setState({acceptModal: false})
  }

 handleMessageData = (message:string) => {
  return message
 }

  getContacts = (name:string) => {
    return name
  }

  showLogs = (post:string,review:string,message:string) => {
    return `${post} posted ${review} ${message}`
  }

  
  handleGetActivityLogData = (posted:string,review:number) => {
    return `${posted} and ${review}`

  }



  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.contactsApiCallId) {
      this.handleFetchContactsAPIResponse(message);
    }

    if (apiRequestCallId === this.banContactApiCallId) {
      this.handleBanStatusAPIResponse(message);
    }
  }

  handleFetchContactsAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.setState({isLoading : false})

    if (responseJson?.error || responseJson?.errors?.length) {
      this.setState({ error: "Unable to get contacts" });
      return;
    }

    if (responseJson?.data) {
      this.setState({
        contacts: responseJson.data,
        normalizedContacts: this.normalizeContacts(responseJson.data),
      });
    }

    if(responseJson?.contact){
      this.setState({
        contacts: [...responseJson.contact.data, ...responseJson.account.data],
        normalizedContacts: [...this.normalizeContacts(responseJson.contact.data), ...this.normalizeAccount(responseJson.account.data)],
      });
    }
  };

  handleBanStatusAPIResponse = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.error || responseJson?.errors?.length) {
      this.setState({ error: "Something went wrong" });
      return;
    }

    if (responseJson?.data) {
      this.fetchContacts();
    }
  };
}
// Customizable Area End
