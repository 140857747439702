// Customizable Area Start
import React, { Component } from "react";
import { styled } from "@material-ui/core/styles";

const MessageBox = styled("div")({
  minHeight: "42px",
  padding: "12px",
  borderRadius: "4px",
  margin: "10px 0px",
  fontFamily: "Inter, 'sans-serif'",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  "&.success": {
    color: "#20a258",
    borderLeft: "4px solid #20a258",
    backgroundColor: "#20a25826",
  },
  "&.error": {
    color: "#DC2626",
    borderLeft: "4px solid #DC2626",
    backgroundColor: "#FEE2E2",
  },
});

interface IProps {
  type?: "success" | "error";
  message: string;
}

export class CustomMessageBox extends Component<IProps> {
  static defaultProps: Partial<IProps> = {
    type: "error",
  };

  private messageBoxRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this.messageBoxRef = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { message } = this.props;
    if (prevProps.message !== message && this.messageBoxRef.current) {
      this.messageBoxRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { message, type } = this.props;

    return message ? (
      <MessageBox
        className={type}
        ref={this.messageBoxRef}
      >
        {message}
      </MessageBox>
    ) : null;
  }
}

export default CustomMessageBox;
// Customizable Area End
