// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { Settings } from './enums';
import {
  AlertSeverity,
  ApiRequest,
  BusinessContactInfo,
  BusinessContactInfoApiResponse,
  CountryCodesApiResponse,
  PostingFeed,
  CustomizePostingFeedGetApiResponse,
  EveryReelButton,
  CustomizeEveryReelButtonGetApiResponse,
} from './interfaces';
import { Message } from '../../../framework/src/Message';
import { getToken } from '../../../components/src/AuthService';
import * as yup from 'yup';
import { parsePhoneNumber } from 'libphonenumber-js';


export const configJSON = require('./config');


export const isValidPhoneNumber=(mobile_number: string):boolean=> {
  try {
    // Assuming `parsePhoneNumber` is a function available in your code
    const parsedPhoneNumber = parsePhoneNumber(`+${mobile_number}`);
    
    return !!parsedPhoneNumber && parsedPhoneNumber.isValid();
  } catch (error) {
    return false;
  }
}

export interface Props {
  classes?: any;
  busninessContactInfo: BusinessContactInfo;
  countryCodesWithFlags: Array<{ label: string; value: string }>;
  onUpdateBusinessContactInfo: (
    formData: BusinessContactInfo,
    resetForm: () => void
  ) => void;
  isFormSubmitting: boolean;
  everyReelButtonInfo: EveryReelButton;
  onUpdateEveryReelButtonInfo: (formData: EveryReelButton) => void;
  postingFeedInfo: PostingFeed;
  onUpdatePostingFeedInfo: (formData: PostingFeed) => void;
  navigation?: any;
  isUpdateSubscriptionDisabled?: any,
}

interface S {
  selectedMenu: Settings;
  countryCodesWithFlags: Array<{ label: string; value: string }>;
  currentBusinessContactInfo: BusinessContactInfo;
  currentPostingFeedInfo: PostingFeed;
  currentEveryReelButtonInfo: EveryReelButton;
  isSnackbarOpen: boolean;
  alert: { severity: AlertSeverity; message: string };
  isFormSubmitting: boolean;
  isUpdateSubscriptionDisabled?: any,
  openFlag: boolean
}

interface SS {
  id: any;
}

export default class Settings2Controller extends BlockComponent<Props, S, SS> {
  countryCodeGetApiCallId: string = '';
  updateBusinessContactGetApiCallId: string = '';
  updateBusinessContactPutApiCallId: string = '';
  customizePostingFeedGetApiCallId: string = '';
  customizePostingFeedPostApiCallId: string = '';
  customizeEveryReelButtonGetApiCallId: string = '';
  customizeEveryReelButtonPostApiCallId: string = '';
  updateBusinessContactResetFormRef: Function | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      selectedMenu: Settings.GettingStarted,
      currentBusinessContactInfo: {
        first_name: '',
        last_name: '',
        country_code: '',
        phone_number: '',
        email: '',
      },
  isUpdateSubscriptionDisabled: true,
      currentPostingFeedInfo: {
        color_option_1: '',
        color_option_2: '',
        comments_enabled: false,
        post_enabled: false,
        review_enabled: false,
      },
      currentEveryReelButtonInfo: {
        hide_button: false,
        border: false,
        caption: '',
        text_bubble: false,
      },
      countryCodesWithFlags: [],
      isSnackbarOpen: false,
      alert: { severity: 'info', message: '' },
      isFormSubmitting: false,
      openFlag: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const ApiCallIdToHandlderMapping = {
      [this.updateBusinessContactGetApiCallId]:
        this.handleGetCurrentBusinessContactInfoResponse,
      [this.countryCodeGetApiCallId]:
        this.handleGetCountryCodesWithFlagsResponse,
        [this.updateBusinessContactPutApiCallId]: (responseJson:any) => {
          this.sendInfoToMainLayout({ refresh: true });
          this.handleUpdateCurrentBusinessContactInfoResponse(responseJson)
         
        },
      [this.customizePostingFeedGetApiCallId]:
        this.handleGetCurrentPostingFeedInfoResponse,
      [this.customizePostingFeedPostApiCallId]:
        this.handleUpdateCurrentPostingFeedInfoResponse,
      [this.customizeEveryReelButtonGetApiCallId]:
        this.handleGetCurrentEveryReelButtonInfoResponse,
      [this.customizeEveryReelButtonPostApiCallId]:
        this.handleUpdateCurrentEveryReelButtonInfoResponse,

    };

    apiRequestCallId != null &&
      ApiCallIdToHandlderMapping[apiRequestCallId]?.(responseJson);
  }

  handleSettingsMenuSelection = (selectedMenu: Settings) => {
    if(selectedMenu !== Settings.TeamMates){
      this.setState({openFlag: false})
    }
    this.setState({ selectedMenu }, () => {
      this.fetchCurrentDetailsForSelectedMenu();
    });
  };

  

  fetchCurrentDetailsForSelectedMenu = () => {
    const settingsMenuToMethodMapping = {
      [Settings.UpdateBusinessContactInfo]: this.getCurrentBusinessContactInfo,
      [Settings.CustomizePostingFeed]: this.getCurrentPostingFeedInfo,
      [Settings.CustomizeEveryreelBtn]: this.getCurrentEveryReelButtonInfo,
    } as any;

    settingsMenuToMethodMapping[this.state.selectedMenu]?.();
  };

  makeApiRequest = ({ endpoint, method, headers, body }: ApiRequest) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  setSnackbarState = (isOpen: boolean) => {
    this.setState({ isSnackbarOpen: isOpen });
  };

  setAlert = ({
    severity,
    message,
  }: {
    severity: AlertSeverity;
    message: string;
  }) => {
    this.setState({ alert: { severity, message } });
  };

  setIsFormSubmitting = (isSubmitting: boolean) => {
    this.setState({ isFormSubmitting: isSubmitting });
  };

  // Update Business Contact implementation start

  getCountryCodesWithFlags = () => {
    this.countryCodeGetApiCallId = this.makeApiRequest({
      endpoint: configJSON.countryCodesGetEndpoint,
      method: configJSON.getApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
    });
  };

  getCurrentBusinessContactInfo = () => {
    this.getCountryCodesWithFlags();
    this.updateBusinessContactGetApiCallId = this.makeApiRequest({
      endpoint: configJSON.updateBusinessContactGetEndpoint,
      method: configJSON.getApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
    });
  };

  updateCurrentBusinessContactInfo = (
    formData: BusinessContactInfo,
    resetForm: () => void
  ) => {
    this.updateBusinessContactResetFormRef = resetForm;
    const { phone_number, country_code, ...businessContactDetails } = formData;
    const phoneNumberWithoutCountryCode = phone_number.substring(country_code.length).trim();
    const requestBody = {
      account: {
        ...businessContactDetails,
        full_phone_number: `+${country_code} ${phoneNumberWithoutCountryCode}`,
      },
    };
    console.log(requestBody,"requestBody")
    this.setIsFormSubmitting(true);
    this.updateBusinessContactPutApiCallId = this.makeApiRequest({
      endpoint: configJSON.updateBusinessContactPutEndpoint,
      method: configJSON.putApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
      body: requestBody,
    });
  };

  handleGetCountryCodesWithFlagsResponse = (
    response: CountryCodesApiResponse
  ) => {
      let countryList = this.countryCodesToDropDown(response);
      this.setState({
        countryCodesWithFlags: countryList,
      });
  };

  countryCodesToDropDown = (response: CountryCodesApiResponse) => {    
    if (!response.data) return [];
    return response.data.map((item: any) => ({
      id: item.id,
      label: ` ${item.attributes.emoji_flag } ${item.attributes.name} (${item.id}) +${item.attributes.country_code}`,
      value: item.attributes.country_code + " " + item.id
    }));
  };

  handleGetCurrentBusinessContactInfoResponse = (
    response: BusinessContactInfoApiResponse
  ) => {
    if (response && response.data) {
      this.setCurrentBusninessContactInfo(response.data);
    } else if (response && response.message) {
      this.setSnackbarState(true);
      this.setAlert({ severity: 'error', message: response.message });
    }
  };

  handleUpdateCurrentBusinessContactInfoResponse = (response: string) => {
    if (response) {
      this.setSnackbarState(true);
      this.setAlert({
        severity: 'success',
        message: configJSON.businessContactUpdatedSuccess,
      });
      this.updateBusinessContactResetFormRef!();
      this.getCurrentBusinessContactInfo();
    }
    this.setIsFormSubmitting(false);
  };

  setCurrentBusninessContactInfo = (
    currentBusinessContactInfo: BusinessContactInfo
  ) => {
    this.setState({ currentBusinessContactInfo });
  };

  // Update Business Contact implementation end

  // Customize Posting Feed implementation start

  getCurrentPostingFeedInfo = () => {
    this.customizePostingFeedGetApiCallId = this.makeApiRequest({
      endpoint: configJSON.customizePostingFeedGetEndpoint,
      method: configJSON.getApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
    });
  };

  handleGetCurrentPostingFeedInfoResponse = (
    response: CustomizePostingFeedGetApiResponse
  ) => {
    if (response && response.data) {
      this.setCurrentPostingFeedInfo(response.data);
    } else if (response && response.message) {
      this.setSnackbarState(true);
      this.setAlert({ severity: 'error', message: response.message });
    }
  };

  setCurrentPostingFeedInfo = (currentPostingFeedInfo: PostingFeed) => {
    this.setState({ currentPostingFeedInfo });
  };

  updateCurrentPostingFeedInfo = (formData: PostingFeed) => {
    const requestBody = {
      posting_feed: {
        ...formData,
      },
    };
    this.setIsFormSubmitting(true);
    this.customizePostingFeedPostApiCallId = this.makeApiRequest({
      endpoint: configJSON.customizePostingFeedPostEndpoint,
      method: configJSON.postApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
      body: requestBody,
    });
  };

  handleUpdateCurrentPostingFeedInfoResponse = (response: string) => {
    if (response) {
      this.setSnackbarState(true);
      this.setAlert({
        severity: 'success',
        message: configJSON.postingFeedUpdatedSuccess,
      });
    }
    this.setIsFormSubmitting(false);
  };

  // Customize Posting Feed implementation end

  // Customize EveryReel Button implementation start

  getCurrentEveryReelButtonInfo = () => {
    this.customizeEveryReelButtonGetApiCallId = this.makeApiRequest({
      endpoint: configJSON.customizeEveryReelButtonGetEndpoint,
      method: configJSON.getApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
    });
  };

  handleGetCurrentEveryReelButtonInfoResponse = (
    response: CustomizeEveryReelButtonGetApiResponse
  ) => {
    if (response && response.data) {
      this.setCurrentEveryReelButtonInfo(response.data);
    } else if (response && response.message) {
      this.setSnackbarState(true);
      this.setAlert({ severity: 'error', message: response.message });
    }
  };

  setCurrentEveryReelButtonInfo = (
    currentEveryReelButtonInfo: EveryReelButton
  ) => {
    this.setState({ currentEveryReelButtonInfo });
  };

  updateCurrentEveryReelButtonInfo = (formData: EveryReelButton) => {
    const requestBody = {
      everyreel_button: {
        ...formData,
      },
    };
    this.setIsFormSubmitting(true);
    this.customizeEveryReelButtonPostApiCallId = this.makeApiRequest({
      endpoint: configJSON.customizeEveryReelButtonPostEndpoint,
      method: configJSON.postApiMethod,
      headers: {
        'Content-Type': configJSON.contentType,
        token: getToken(),
      },
      body: requestBody,
    });
  };

  handleUpdateCurrentEveryReelButtonInfoResponse = (response: string) => {
    if (response) {
      this.setSnackbarState(true);
      this.setAlert({
        severity: 'success',
        message: configJSON.everyReelButtonUpdatedSuccess,
      });
    }
    this.setIsFormSubmitting(false);
  };

 validationSchema = yup.object().shape({
    first_name: yup.string().required(configJSON.firstNameErrorMessage),
    last_name: yup.string().required(configJSON.lastNameErrorMessage),
    phone_number:yup.string().required(configJSON.phoneNumberErrorMessage).
    test('phoneno', "Enter a valid phone number", function (value:any) {
      return value && isValidPhoneNumber(value);
    }),
    

    email: yup
      .string()
      .email(configJSON.emailInvalidErrorMessage)
      .required(configJSON.emailRequiredErrorMessage),
  });

  validationSchemaCustomiseEveryReel = yup.object().shape({
    caption: yup.string().required(configJSON.captionRequired),
  });

  validationSchemaCustomisePostingFeed = yup.object().shape({
    color_option_1: yup
      .string()
      .matches(configJSON.hexColorCodeRegExp, {
        message: configJSON.postThemeColorInvalid,
      })
      .required(configJSON.postThemeColor1Required),
    color_option_2: yup
      .string()
      .matches(configJSON.hexColorCodeRegExp, {
        message: configJSON.postThemeColorInvalid,
      })
      .required(configJSON.postThemeColor2Required),
  });
  sendInfoToMainLayout = (data: any) => {
    const msg = new Message(getName(MessageEnum.ConnectTOSidebar))
    msg.addData(getName(MessageEnum.ShouldUpdate), data)
    this.send(msg)
  }
  
  async componentDidMount() {
    const id=this.props.navigation.getParam("id")
    if(id){
      this.setState({selectedMenu:Settings.UpdateSubscription,isUpdateSubscriptionDisabled: false})
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      this.setState({selectedMenu: Settings.SocialIntegrations})
    }
  }

  componentDidUpdate = async (prevProps: Props, prevState: S) => {
    const teammate=!!new URLSearchParams(window.location.search).get('teammate')!
    if (
      prevState.openFlag !== teammate && teammate
    ) {
      this.setState({openFlag : true})
      this.handleSettingsMenuSelection(Settings.TeamMates)
    }
  };
  // Customize Posting Feed implementation end
}


// Customizable Area End
