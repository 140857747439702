// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import {
  EverybrandIcon, commentIcon, copyCode, heartIcon, starIcon, tutorialIcaon,
} from "./assets";
import { withStyles, createStyles, Theme, styled } from "@material-ui/core/styles";
import ConnectWebsiteController from "./ConnectWebsiteController.web";
import { Alert } from "@material-ui/lab";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CloseRounded from "@material-ui/icons/CloseRounded";

const CustomSkeletonLoader = styled(Box)({
  backgroundColor: "#F1F5F9"
})

const CustomLoaderBottom = () => {
  return (
    <Box>
      <CustomSkeletonLoader borderRadius="78px" height="45px" width="95px" />
      <CustomSkeletonLoader marginTop={2} borderRadius="78px" height="13px" width="209px" />
      <CustomSkeletonLoader marginTop={2} borderRadius="78px" height="13px" width="209px" />
      <CustomSkeletonLoader marginTop={2} borderRadius="78px" height="13px" width="106px" />
    </Box>
  )
}

export class ConnectWebsite extends ConnectWebsiteController {
  render() {
    const { classes }: any = this.props;
    const {
      profilePic,
      widgetCode,
      commentCount,
      likesCount,
      reviewsCount,
      tutorialModal,
      alertMessage,
      alertType,
      isLoading
    } = this.state

    return (
      <Container maxWidth={false} className={classes.mainContConnect}>
        <Dialog
          open={tutorialModal}
          classes={{
            paper: classes.tutorialPaper
          }}
          onClose={this.closeTutorialModal}
          data-test-id="webUpdateModal"
        >
          <DialogTitle>
          <Box className={classes.connectWebsiteModalBox}>
            <Typography className={classes.headingConnect}>
              Follow these steps
            </Typography>
            <IconButton data-test-id="close-tutorial" size="small" onClick={this.closeTutorialModal}>
              <CloseRounded fontSize="small" />
            </IconButton>
          </Box>
          </DialogTitle>
        </Dialog>
        <Box className={classes.firstBoxConnect}>
          <Typography className={classes.headingConnect}>
            Connect Website
          </Typography>
          <Box>
            <img src={EverybrandIcon} alt="" />
          </Box>
        </Box>
        <Typography className={classes.infoText}>
          Connect your site and attract the attention of your website visitors with your Everybrand platform
        </Typography>
        <CustomLoader isLoading={isLoading} loaderSize={30} wrapperHeight="60vh">
          <Box className={classes.secondBoxConnect}>
            <Typography className={classes.headingConnect}>
              Let’s get started!
            </Typography>
            <Box>
              <Button
                data-test-id="openTutorialModal"
                className={classes.buttonConnect}
                onClick={this.openTutorialModal}
              >
                <img src={tutorialIcaon} alt="Tutorial" />  View Tutorial
              </Button>
            </Box>
          </Box>
          <Typography >
            <ul>
              <li>
                <span className={classes.stepText}>Step 1</span><span className={classes.infoText}> Press the “Copy Code” button</span>
              </li>
            </ul>
          </Typography>
          <Box className={classes.outerBox}>
            <Box className={classes.codeBox}>
              <Typography className={classes.codeTextMain}>
                {widgetCode}
              </Typography>
            </Box>
            <Box className={classes.copyCodeBox}>
              <Typography
                data-test-id="copyCodeBtn"
                className={classes.copyCodeText}
                onClick={this.handleCopy}
              >
                Copy Code
                <img src={copyCode} alt="Copy" />
              </Typography>
            </Box>
          </Box>
          <Typography >
            <ul>
              <li>
                <span className={classes.stepText}>Step 2</span><span className={classes.infoText}> Go to your website and add the copied code between the footer of your website.Replace<br/> actual code snippet with this placeholder CODE_SNIPPET</span>
              </li>
            </ul>
          </Typography>
          <Box className={classes.outerBox}>
            <Box className={classes.step2Box}>
              <Box className={classes.step2InnerBox}>
                <Box className={classes.iconContainer}>
                  <Box className={classes.redIcon} />
                  <Box className={classes.yellowIcon} />
                  <Box className={classes.greenIcon} />
                  <CustomSkeletonLoader height="24px" flex={1} marginLeft={2} />
                </Box>
                <CustomSkeletonLoader height="156px" marginTop={5} />
                <Box className={classes.step2BottomLoaderWrapper}>
                  <CustomLoaderBottom />
                  <CustomLoaderBottom />
                </Box>
              </Box>
              <Box className={classes.snippetBox}>
                <Typography className={classes.codeText}>
                  {"<body>"}<br /><br />
                  {"<footer>"}<br /><br />
                  {"CODE_SNIPPET"}<br /><br />
                  {"</footer>"}<br /><br />
                  {"</body>"}<br /><br />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography >
            <ul>
              <li>
                <span className={classes.stepText}>Step 3</span><span className={classes.infoText}> That’s it! Now comes the fun part of increasing your leads, web conversions, raving fans,<br /> and conversations.</span>
              </li>
            </ul>
          </Typography>
          <Box className={classes.outerBox}>
            <Box className={classes.step3Box}>
              <Box width="100%">
                <Box className={classes.iconContainer}>
                  <Box className={classes.redIcon} />
                  <Box className={classes.yellowIcon} />
                  <Box className={classes.greenIcon} />
                  <CustomSkeletonLoader height="24px" flex={1} marginLeft={2} />
                </Box>
                <CustomSkeletonLoader height="156px" marginTop={4} />
                <Box className={classes.step2BottomLoaderWrapper} marginRight={3}>
                  <CustomLoaderBottom />
                  <CustomLoaderBottom />
                  <CustomLoaderBottom />
                </Box>
              </Box>
              <Box className={classes.iconBox}>
                <Box className={classes.icon}>
                  <img src={heartIcon} alt="" />
                  <Typography>{likesCount}</Typography>
                </Box>
                <Box className={classes.icon}>
                  <img src={commentIcon} alt="" />
                  <Typography>{commentCount}</Typography>
                </Box>
                <Box className={classes.icon}>
                  <img src={starIcon} alt="" />
                  <Typography>{reviewsCount}</Typography>
                </Box>
                <Box className={classes.icon}>
                  <Box className={classes.profileImage}>
                    <img src={profilePic} alt="Profile" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomLoader>
        <Snackbar
          data-test-id="dialogDelete"
          open={!!alertMessage}
          autoHideDuration={3000}
          onClose={this.closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert variant="filled" onClose={this.closeAlert} severity={alertType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export const commonStyles: any = {
  textHeadingConnect: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.12px",
  },
  flexDRSB: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textConnect: {
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize:"14px",
    lineHeight: "22px"
  },
  buttonConnect: {
    backgroundColor: "#FFC629",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    padding: "5px 40px",
    fontFamily:"Inter"
  },
  circle: {
    height: "23px",
    width: "23px",
    borderRadius: 50,
    margin:"3px"
  }
};

const styles = (theme: Theme) =>
  createStyles({
    mainContConnect: {
      padding: "40px",
      "& li": {
        color: "#64748B"
      }
    },
    tutorialPaper: {
      width: "100%",
      maxWidth: "1027px",
      borderRadius: "8px 8px 32px 8px"
    },
    headingConnect: {
      ...commonStyles.textHeadingConnect
    },
    firstBoxConnect: {
      ...commonStyles.flexDRSB
    },
    infoText: {
      ...commonStyles.textConnect
    },
    buttonConnect: {
      ...commonStyles.buttonConnect,
      padding: "10px 25px",
      width: "100%",
      margin: "10px 0",
      height: "51px",
      maxWidth: "211px",
      "& img": {
        marginRight: "15px",
        width: "24px",
        height: "24px"
      },
      "&:hover": {
        backgroundColor: "#FFD77E",
      }
    },
    secondBoxConnect: {
      ...commonStyles.flexDRSB,
      paddingTop: 40,
      alignItems: "center"
    },
    stepText: {
      ...commonStyles.textConnect,
      fontWeight: 700
    },
    outerBox: {
      margin: "20px 20px 25px",
      maxWidth: "800px"
    },
    codeBox: {
      borderRadius: "13px 13px 0px 0px",
      padding: 20,
      backgroundColor: "#2F3941"
    },
    copyCodeBox: {
      padding: "10px",
      backgroundColor: "#FFC629",
      display: "flex",
      justifyContent: "end"
    },
    codeText: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "16px",
      color: "#FFC629",
    },
    codeTextMain: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "16px",
      color: "#FFC629",
      overflow: "hidden",
      wordBreak: "break-all"
    },
    copyCodeText: {
      ...commonStyles.textHeadingConnect,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      "& > img": {
        margin: "0px 20px",
        height: "22px",
        width: "22px"
      }
    },
    step2Box: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "#FFFFFF",
      height: 435,
    },
    step2InnerBox: {
      padding: "25px 0px 25px 25px",
      width: "70%"
    },
    step2BottomLoaderWrapper: {
      marginTop: "24px",
      display: "flex",
      gridGap: "10px",
      justifyContent: "space-between",
      paddingRight: "25px"
    },
    step3Box: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "20px 20px 0px 0px",
      backgroundColor: "#FFFFFF",
      height: 435,
      padding: "25px",
      position: "relative"
    },
    snippetBox: {
      width: "30%",
      padding: 20,
      backgroundColor: "#2F3941",
      height: 435,
      borderRadius: "0px 20px 0px 0px",
    },
    connectWebsiteModalBox: {
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    iconBox: {
      position: "absolute",
      bottom: 0,
      right: "20px",
      marginBottom: "8px"
    },
    icon: {
      margin: "8px 0px 8px 8px",
      textAlign: "center",
      "& > p": {
        fontFamily: "Inter, 'sans-serif'",
        fontSize: "8px",
        fontWeight: 400,
        marginTop: "2px"
      }
    },
    profileImage: {
      borderRadius: 50,
      "& > img": {
        height: "33px",
        width: "33px",
        borderRadius: 50,
      }
    },
    redIcon: {
      ...commonStyles.circle,
      backgroundColor: "#CA4467"
    },
    yellowIcon: {
      ...commonStyles.circle,
      backgroundColor: "#EAC543"
    },
    greenIcon: {
      ...commonStyles.circle,
      backgroundColor: "#6AC181"
    },
    iconContainer:{
      display:"flex",
      alignItems: "center"
    }
  });

export default withStyles(styles)(ConnectWebsite)
// Customizable Area End
