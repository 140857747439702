// Customizable Area Start
import React from "react";
import {
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  TextField,
  Box,
  Checkbox,
  Avatar,
  Dialog,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { styled } from "@material-ui/core/styles";
import { ExpandMoreRounded } from "@material-ui/icons";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import ReviewInviteController, { IContact } from "./ReviewInviteController.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
import CustomButton from "../../../components/src/CustomButton.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddContactModal from "./AddContactModal.web";
import BulkInviteFriends from "./BulkInviteFriends.web";
import { InviteFriendsStepType } from "./enums";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CustomMessageBox from "../../../components/src/CustomMessageBox.web";

const CustomCheckBoxIcon = styled(CheckBoxIcon)({
  color: "#FFC629",
});

const StyledDialog = styled(CustomDialog)({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "555px",
  },
});

const CustomDialogTitle = styled(DialogTitle)({
  paddingTop: "45px",
  "& *": {
    fontFamily: "Inter, 'sans-serif'",
  },
});

const DialogContentNested = styled(DialogContent)({
  padding: "0px !important",
  height: "95vh"
});

const SelectedUsersWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "5px",
  "& p": {
    color: "#334155",
    fontSize: "10px",
    fontWeight: 700,
  },
  "& button": {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    color: "#0069FF",
    fontSize: "10px",
    fontWeight: 700,
    "&:hover": {
      fontWeight: 600,
    },
  },
});

const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

const MainHeading = styled(Typography)({
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
  textAlign: "center",
  color: "#000000",
});

const SubHeading = styled(Typography)({
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily: "Inter, 'sans-serif'",
  textAlign: "center",
  color: "#64748B",
  marginTop: "5px",
});

const CustomDialogContent = styled(DialogContent)({
  padding: "0px",
  "& *": {
    fontFamily: "Inter, 'sans-serif'",
  },
});

const ContentWrapper = styled(DialogContent)({
  padding: "0px 40px 40px",
});

const ActionBanner = styled(Box)({
  height: "66px",
  backgroundColor: "#FFC629",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  "& p": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& button": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
  },
});

const InfoText = styled(Typography)({
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  padding: "10px 0",
  color: "#334155",
});

const CustomAutocomplete = styled(Autocomplete)({
  fontFamily: "Inter, 'sans-serif'",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  "& .MuiOutlinedInput-root": {
    border: "none",
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiAutocomplete-inputRoot": {
    maxHeight: "90px",
    overflowY: "auto",
  },
});

const ReviewMessageContainer = styled(Box)({
  width: "100%",
  padding: "12px 15px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "24px",
  fontFamily: "Inter",
  color: "#475569",
  minHeight: "160px",
  "& b": {
    color: "#000"
  }
});

const AutoLinkField = styled(TextField)({
  border: "1px solid #E2E8F0",
  borderRadius: "8px",
  "& input": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#64748B",
    padding: "14px"
  },
  "& fieldset": {
    border: "none",
  },
});

const CustomAvatarGroup = styled(AvatarGroup)({
  marginTop: "15px",
  "& .MuiAvatar-root": {
    border: "2px solid #FFC629",
    width: 35,
    height: 35,
    fontSize: "15px",
  },
});

const InviteButton = styled(CustomButton)({
  maxWidth: "212px",
  height: "56px",
});

const checkBoxOutlinedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CustomCheckBoxIcon fontSize="small" />;

class ReviewInvite extends ReviewInviteController {
  render() {
    const {
      contacts,
      invitationSubject,
      selectedContacts,
      isAddContactDialog,
      isBulkInviteDialog,
      isGettingContacts,
      message,
      loading,
      messageType
    } = this.state;
    const selectedContactsLength = selectedContacts.length
    const link = window.location.origin

    return (
      <>
        <StyledDialog
          open={true}
          data-testid="review-invite-dialog"
          onClose={this.handleClose}
          disableEscapeKeyDown
        >
          <CustomDialogTitle>
            <CloseButton
              data-testid="close-icon"
              size="small"
              onClick={this.closeReviewInvite}
            >
              <ClearRoundedIcon />
            </CloseButton>
            <MainHeading data-testid="review-invite">
              Send a Review Invite
            </MainHeading>
            <SubHeading>
              Grow your reputation by inviting your contacts <br /> to leave you
              a review
            </SubHeading>
          </CustomDialogTitle>
          <CustomDialogContent>
            <ActionBanner>
              <Typography>Send to multiple contacts at once</Typography>
              <button
                data-testid="bulk-invite-button"
                onClick={this.openBulkInviteDialog}
              >
                Try a bulk invite.
              </button>
            </ActionBanner>
            <ActionBanner>
              <Typography>Is your contact not listed?</Typography>
              <button
                data-testid="add-contact"
                onClick={this.openAddContactDialog}
              >
                Add it here
              </button>
            </ActionBanner>
            <ContentWrapper>
              <InfoText>Send to</InfoText>
              <CustomAutocomplete
                multiple
                options={contacts}
                disableCloseOnSelect
                value={selectedContacts}
                loading={isGettingContacts}
                data-testid="send-to-dropdown"
                popupIcon={<ExpandMoreRounded />}
                loadingText={<CustomLoader isLoading={true} loaderSize={18} />}
                onChange={this.handleContactsSelectionChange}
                getOptionLabel={(option: unknown) => {
                  const {
                    attributes: { first_name, last_name },
                  } = option as IContact;
                  return `${first_name} ${last_name}`;
                }}
                renderOption={(option, { selected }) => {
                  const {
                    id,
                    attributes: { first_name, last_name },
                  } = option as IContact;
                  return (
                    <React.Fragment key={id}>
                      <Checkbox
                        icon={checkBoxOutlinedIcon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      />
                      {first_name} {last_name}
                    </React.Fragment>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select contact(s)"
                  />
                )}
              />
              <CustomAvatarGroup max={3}>
                {selectedContacts.map((teammate) => {
                  const {
                    id,
                    attributes: { first_name, last_name, profile_pic_url },
                  } = teammate;
                  return (
                    <Avatar
                      key={id}
                      src={profile_pic_url?.url}
                      alt={`${first_name} ${last_name}`}
                    />
                  );
                })}
              </CustomAvatarGroup>
              {selectedContactsLength > 0 && (
                <SelectedUsersWrapper>
                  <Typography>
                    {selectedContacts
                      .slice(0, 2)
                      .map((contact) => {
                        const {
                          attributes: { first_name, last_name },
                        } = contact;
                        return `${first_name} ${last_name}`;
                      })
                      .join(", ")}
                  </Typography>
                  {selectedContactsLength > 2 && (
                    <button onClick={this.openBulkInviteDialog}>
                      &nbsp;and {selectedContactsLength - 2} other users
                    </button>
                  )}
                </SelectedUsersWrapper>
              )}
              <Box paddingTop="10px">
                <InfoText>Template Preview</InfoText>
              </Box>
              <ReviewMessageContainer
                data-test-id="textAreaField"
                dangerouslySetInnerHTML={{ __html: invitationSubject }}
              />
              <Box paddingTop="10px">
                <InfoText>Autolink</InfoText>
              </Box>
              <AutoLinkField
                id="outlined-basic"
                variant="outlined"
                data-test-id="legal_business_name"
                fullWidth
                disabled
                value={link}
              />
              <CustomMessageBox type={messageType} message={message} />
              <Box paddingTop="20px">
                <InviteButton
                  data-testid="sendBtn"
                  isLoading={loading}
                  disabled={selectedContactsLength === 0}
                  onClick={this.handleReviewInviteClick}
                >
                  Send Invite
                </InviteButton>
              </Box>
            </ContentWrapper>
          </CustomDialogContent>
        </StyledDialog>
        <Dialog data-testid="add-contact-dialog" open={isAddContactDialog}>
          <DialogContentNested>
            <AddContactModal
              addContact={this.addContact}
              setStep={this.closeAddContactDialog}
              currentStep={InviteFriendsStepType.INVITE_FRIEND}
            />
          </DialogContentNested>
        </Dialog>
        <Dialog
          data-testid="bulk-invite-dialog"
          open={isBulkInviteDialog}
          maxWidth="md"
        >
          <DialogContentNested>
            <BulkInviteFriends
              contacts={this.normalizeContacts(contacts)}
              addContact={this.addContact}
              addContactModal={this.openAddContactDialog}
              checkBulkContact={this.toggleCheckBulkContact}
              setStep={this.closeBulkInviteDialog}
              submitInvites={this.closeBulkInviteDialog}
            />
          </DialogContentNested>
        </Dialog>
      </>
    );
  }
}

export default ReviewInvite;
// Customizable Area End
