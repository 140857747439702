import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface BrandAttributesCreate {
  activated: boolean;
  country_code: string;
  email: string;business_website: string;
  zip_code: string;
  social_phone_number: string;
  follows: {
    followers: string;
    followings: string;
  };
  subscription_paused: boolean;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  cover_photo: {
    url : string
  } | null;
  full_name: string | null;
  first_name: string;
  sign_up_step: number;
  last_name: string;
  device_id: string | null;
  full_phone_number: string;
  unique_auth_id: string;
  pin: number;
}
interface BrandCreate {
  id: string;
  type: string;
  attributes: BrandAttributesCreate;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: any;
  token: string;
  name: string;
  description: string;
  price: any;
  currency: string;
  category_id: string;
  image: any;
  uploadedImages: any;
  AllCategory: any[];
  id: any;
  refresh: boolean;
  file: any;
  profileImageData: any;
  selectedCategory: any;
  allCategories: any[];
  brands: BrandCreate[];
  InitialBrands: BrandCreate[];
  selectedBrand: {id: string, name: string}[];
  eupoststep: number,
  starRating: number
  brandsIFollowCreate: BrandCreate[];
  suggestedBrandsCreate: BrandCreate[];
  top6CreatePost: BrandCreate[];
  euCreateLoading: boolean;
  reviewText:string;
  currentBrandId:any;
  reviewResult:string;
  reviewMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: any;
  addpostApiCallId: any;
  updatePostApiCallId: any;
  euCreateBrandsApiCallId: string ="";
  euCreateBrandsOptionsApiCallId: string ="";
  followBrandPostApiCallId: string = "";
  reviewAccountApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {},
      selectedCategory: "",
      allCategories: [],
      brands: [],
      InitialBrands: [],
      selectedBrand: [],
      eupoststep: 1,
      starRating: 5,
      brandsIFollowCreate: [],
      suggestedBrandsCreate: [],
      top6CreatePost: [],
      euCreateLoading: false,
      reviewText:"",
      currentBrandId:null,
      reviewResult:"",
      reviewMessage:"",
    };
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    var authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    this.getSuggestedAndFollowBrandCreate();
    this.getBrandForCreatePostOptions();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const item = message.getData(getName(MessageEnum.PostDetailDataMessage));
      if (item) {
        this.setState({
          name: item.data.attributes.name,
          description: item.data.attributes.description,
          price: item.data.attributes.price.toString(),
          currency: "$",
          category_id: item.data.attributes.category.data.attributes.id,
          id: item.data.attributes.id,
          profileImageData: {
            data: item.data.attributes.product_image,
            content_type: "image/jpeg",
            filename: "image.jpeg"
          },
          image: ""
        });
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      //runEngine.debugLog("Message Recived", message);
      let token =
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjM1LCJleHAiOjE2MjI2NTgwMDd9.iXAq1PM-c2KYdJK5yctjnJ3JGF1d9ABjw6puBZMXTzprSJ7EBQmp9nevWMnRgc9tCgTSFJh7br4_F-FTxLpxRQ";
      //message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.getAllCategory();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
     this.apiResponseCheck(apiRequestCallId,responseJson,errorReponse)

      if (apiRequestCallId === this.reviewAccountApiCallId) {
        if (responseJson.error) {
          this.setState({ reviewResult: responseJson.error });
          setTimeout(() => {
            this.setState({ reviewResult: "" });
          }, 5000); 
        }else{
          this.setState({reviewMessage:"your review has been successfull !"})
          setTimeout(() => {
          this.setState({reviewMessage:" "})
          }, 5000);
        }
      }
    }

  }

  apiResponseCheck(apiRequestCallId: string,responseJson:any,errorReponse: any){
    if (responseJson && responseJson.errors) {
      this.setState({ refresh: false });
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    } else if (responseJson) {
      if (apiRequestCallId === this.apiPostItemCallId) {
        this.setState({ PostData: responseJson, refresh: false });
      } else if (apiRequestCallId === this.addpostApiCallId) {
        this.setState({ refresh: true });
        this.props.navigation.state.params.callback();
        this.props.navigation.goBack();
      } else if (apiRequestCallId === this.apiGetCategoryCallID) {
        var allCategories: any[] = [];
        let categories: string[] = [];
  
        const data = responseJson;
  
        data.forEach((item: any) => {
          if (categories.indexOf(item.data.attributes.name) === -1) {
            var category = {
              value: item.data.attributes.id,
              label: item.data.attributes.name
            };
            allCategories.push(category);
            categories.push(item.data.attributes.name);
          }
        });
  
        this.setState({
          AllCategory: responseJson,
          allCategories: allCategories
        });
        this.getPostData();
      } else if (apiRequestCallId === this.updatePostApiCallId) {
        this.setState({ refresh: true });
        this.getPostData();
        this.props.navigation.state.params.callback();
        this.props.navigation.goBack();
      } else if (apiRequestCallId === this.DeleteApiCallId) {
        this.getPostData();
      }
      this.handleApiCallResponseJson(apiRequestCallId, responseJson)
    } else if (apiRequestCallId === this.DeleteApiCallId) {
      this.getPostData();
    } else if (errorReponse) {
      this.setState({ refresh: false });
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  

  createPostCreation() {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert("Error", "Please enter all mandatory fields");
    } else {
      this.AddPostCreation();
    }
  }

  goToItemDetails(item: any, isEdit: boolean) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      isEdit ? "PostCreation" : "PostDetails"
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    raiseMessage.addData(getName(MessageEnum.PostDetailDataMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }

  editNavigation = (item: any) => {
    this.goToItemDetails(item, true);
  };

  navigateToDetails = (item: any) => {
    this.goToItemDetails(item, false);
  };
  reviewAccount(rating: number | null) {
 
    const brandIds = this.state.selectedBrand.map((brand) => brand.id);
  
    if (brandIds.length === 0) {
      return false;
    }
  
    const header = {
      "Content-Type": configJSON.postContentType,
      token: localStorage.getItem("EUAuthToken"),
    };
  
  
    const attrs = {
      account_id: brandIds, 
      review: {
        title: "hello", // Assume this is stored in state or passed dynamically
        description: "Good", // Assume this is stored in state or passed dynamically
        rating: rating, // Rating passed to the function
      },
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reviewAccountApiCallId = requestMessage.messageId;
  
    // Set request endpoint, headers, and body
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewOnBUAccount
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod // Should be "POST" or the appropriate HTTP method
    );
  
    // Log for debugging
    console.log("Payload being sent to backend:", attrs);
  
    // Send the request
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  

  AddPostCreation(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const attrs = {
      data: {
        attributes: {
          name: this.state.name,
          description: this.state.description,
          price: this.state.price,
          currency: "$",
          category_id: this.state.category_id,
          image: this.state.profileImageData
        }
      }
    };
    // const data = {
    //   attributes: attrs,
    // };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addpostApiCallId = requestMessage.messageId;
    // const httpBody = {
    //   data: data,
    // };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log(attrs, "attrsattrsattrsattrsattrs");
    return true;
  }

  getAllCategory() {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiGetCategoryCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCatergoryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage, ", requestMessage);

    this.apiPostItemCallId = requestMessage.messageId;
    console.log(
      "requestMessage, ",
      getName(MessageEnum.RestAPIResponceEndPointMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostApiMethodType
    );
    //console.log('requestMessage@, ',requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateCreatePostData(Id: any) {
    if (
      this.state.category_id === "" ||
      this.state.description === "" ||
      this.state.name === "" ||
      this.state.price === ""
    ) {
      this.showAlert("Error", "Please enter all mandatory fields");
      return false;
    } else {
      const header = {
        "Content-Type": configJSON.postContentType,
        token: this.state.token
      };
      const attrs = {
        data: {
          attributes: {
            name: this.state.name,
            description: this.state.description,
            price: this.state.price,
            currency: "$",
            category_id: this.state.category_id,
            image: this.state.profileImageData
          }
        }
      };
      // const data = {
      //   attributes: attrs,
      // };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updatePostApiCallId = requestMessage.messageId;
      // const httpBody = {
      //   data: data,
      // };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postGetUrl + "/" + `${Id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(attrs)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchPostAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  deleteRecord(Id: any) {
    Alert.alert(
      "Warning",
      "Are you sure for delete this post?",
      [
        { text: "No", onPress: () => { }, style: "cancel" },
        {
          text: "Yes",
          onPress: () => {
            this.setState({ refresh: true });
            this.delete(Id);
          }
        }
      ],
      { cancelable: false }
    );
  }

  delete(Id: any) {
    this.setState({ refresh: true });
    const header = {
      "Content-Type": configJSON.postContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postGetUrl + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePostAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.getPostData();
    return true;
  }

  txtInputProductNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });
      //@ts-ignore
      this.txtInputProductNameProps.value = text;
    }
  };

  txtInputProductDiscripationProps = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
      //@ts-ignore
      this.txtInputProductDiscripationProps.value = text;
    }
  };

  ImageData = {
    onChangeText: (text: string) => {
      this.setState({ image: text });
      //@ts-ignore
      this.ImageData.value = text;
    }
  };

  DropDownProps = {
    onChangeText: (text: string) => {
      this.setState({ category_id: text });
      //@ts-ignore
      this.DropDownProps.value = text;
    }
  };

  txtInputProductPriceProps = {
    onChangeText: (text: string) => {
      this.setState({ price: text });
      //@ts-ignore
      this.txtInputProductPriceProps.value = text;
    }
  };

  chooseImage = () => {
    this.showAlert("Error", "Image Picker Not Implemented");
  };

  valueExtractor1 = (val: { data: { attributes: { id: any } } }): any => {
    return val.data.attributes.id;
  };

  onValueHanndler = (val: { data: { attributes: { name: any } } }): any => {
    return val.data.attributes.name;
  };

  handleCatChangeChange = (selectedOption: any) => {
    this.setState({
      selectedCategory: selectedOption,
      category_id: selectedOption.value
    });
  };
  SearchValue = (search: string) => {
    if (search.trim() === "") {
      this.setState({ brands: this.state.InitialBrands });
      return
    }

    const filterData = this.state.InitialBrands.filter((brand) =>
      brand.attributes.first_name.toLowerCase().includes(search.toLowerCase()) || brand.attributes?.last_name?.toLowerCase().includes(search.toLowerCase()) || brand?.attributes?.full_name?.toLowerCase().includes(search.toLowerCase())
    );
    this.setState({ brands: filterData });
  }

  SetEuPoststep = (step: number) => {
    this.setState({ eupoststep: step })
  }

  ChangeRating = (rating: number | null) => {
    if (rating) {
      this.setState({ starRating: rating });
      this.reviewAccount(rating);
    }
  };
  handleBrandSelection(brandSelected: { id: string, name: string }, index: number) {
    console.log('Selected Brand:', brandSelected);
    console.log('Selected Brand Index:', index);
  
    if (index >= this.state.brands.length) {
      console.log("Navigating to profile for brand ID:", brandSelected.id);
      this.handleNavigateToProfile(+brandSelected.id);
      return;
    }
  
    const includeBrand = this.state.selectedBrand.some(elem => elem.id === brandSelected.id);
    console.log('Brand is already selected:', includeBrand);
  
    this.setState((prevState) => ({
      selectedBrand: includeBrand
        ? prevState.selectedBrand.filter(elem => elem.id !== brandSelected.id)
        : [...prevState.selectedBrand, brandSelected],
      currentBrandId: brandSelected.id,
    }), () => {
      console.log("Updated Selected Brands:", this.state.selectedBrand);
      console.log("Current Brand ID:", this.state.currentBrandId);
    });
  }
  

  handleNavigateToProfile(id: number) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EuProfileView");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(msg);
  }

  handleContinue(){
    this.setState((prevState) => ({
      eupoststep: prevState.eupoststep + 1
    }));
  }

  handleApiCallResponseJson = (apiRequestCallId : string, responseJson : any) => {
    if (apiRequestCallId === this.euCreateBrandsApiCallId) {
      this.setState({
        brandsIFollowCreate: responseJson.followings.data,
        suggestedBrandsCreate: responseJson.suggested_brands.data,
        top6CreatePost: responseJson.top6.data
      })
    }

    if (apiRequestCallId === this.euCreateBrandsOptionsApiCallId) {
      this.setState({
        brands: [
          ...responseJson.top6.data, 
          ...responseJson.followings.data
        ],
        InitialBrands: [
          ...responseJson.top6.data, 
          ...responseJson.followings.data
        ],
        euCreateLoading: false
      })
    }

    if(apiRequestCallId === this.followBrandPostApiCallId && responseJson.data) {
      this.getSuggestedAndFollowBrandCreate()
    }
  }

  getSuggestedAndFollowBrandCreate = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euCreateBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUCreateBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUCreateBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUCreateBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandForCreatePostOptions = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    this.setState({euCreateLoading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euCreateBrandsOptionsApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUCreateBrands,
      token: EuToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBrandsOptionsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUCreateBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  followBrandPostApi = async (brandId: string) => {
    const token = await getStorageData(configJSON.EU_AUTH_TOKEN)

    const httpBody = {
      data:{
          attributes:{
              followed_user_id: brandId
          }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followBrandPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followBrandApiEndpoint
    );
    const header = {
      "Content-Type": configJSON.libraryContentType,
      token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
   )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
