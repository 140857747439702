// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";
import {
  ThemeProvider,
  withStyles,
  createStyles
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import EndUserEmailSignupController, {
  ICountryCode
} from "./EndUserEmailSignupController.web";
import {
  commonStyles,
  themeWhite,
  themeYellow
} from "../../../framework/src/enduserTheme";
import { lockIcon } from "./assets";
import MobileLoader from "../../../components/src/MobileLoader";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import "react-phone-input-2/lib/style.css";
import {
  handleAndConditions,
  handleOrConditions
} from "../../../components/src/CommonFunctions";
import CustomBackButton from "../../../components/src/CustomBackButton.web";
const configJSON = require("./config.js");

const validation_Schema_stepOne = yup.object({
  thoughts: yup
    .string()
    .required("Your thoughts are required")
    .matches(/[a-zA-Z]/, "Only alphabets are allowed")
    .test("max-words", "The shared thoughts cannot have more than 40 words", value => {
      const wordCount = value ? value.trim().split(/\s+/).length : 0;
      return wordCount <= 40;
    })
});

const validation_Schema_stepTwo = yup.object({
  userName: yup
    .string()
    .required("Full name is required")
    .matches(/^[a-zA-Z]{2,}( [a-zA-Z]{2,})+$/, "Please enter a valid full name")
    .matches(
      /^[a-zA-Z ]*$/,
      "Name cannot contain special characters or numbers"
    )
});

const validation_Schema_stepThree = yup.object({
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .min(10, "Must be at least 10 digits")
});

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    color: "#FFC629",
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      backgroundColor: "#FFC629",
      "& + $track": {
        backgroundColor: "#FFC629",
        opacity: 1,
        border: "1px solid #000"
      }
    },
    "&$focusVisible $thumb": {
      color: theme.palette.common.white,
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #000`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
  // @ts-ignore
}))(({ classes, ...props }) => {
  return (
    <Switch
      data-test-id="switchBtn"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export class EndUserEmailSignUp extends EndUserEmailSignupController {
  render() {
    const { classes }: any = this.props;

    const StepTwo = () => {
      return (
        <Formik
          data-test-id="stepTwoForm"
          initialValues={{
            userName: ""
          }}
          validationSchema={validation_Schema_stepTwo}
          onSubmit={values => {
            this.setState({
              userName: values.userName,
              stepTwo: false,
              stepThree: true
            });
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            handleSubmit
          }) => (
            <>
              <Box className={classes.backIconBox}>
                <CustomBackButton
                  data-test-id="backToStepOne"
                  handleClick={this.redirectToFirstPage}
                />
              </Box>
              <Box className="secondStepMainBox">
                <Typography
                  data-test-id="secondStepText1"
                  align="center"
                  className={classes.centerTextSU1}
                >
                  {this.state.thoughts}
                </Typography>
              </Box>
              <Box className={classes.addNameBox}>
                <Typography
                  data-test-id="secondStepText2"
                  className={classes.textTwoSU}
                >
                  {configJSON.addPersonalTouchMessage}
                </Typography>
                <Typography className={classes.textOne}>
                  {configJSON.whatIsFullNameLabel}
                </Typography>
                <input
                  name="userName"
                  data-test-id="secondStepInput"
                  placeholder=" Add Your Name"
                  className={classes.nameInputSU}
                  value={values.userName}
                  onChange={handleChange("userName")}
                  onBlur={handleBlur("userName")}
                />
                {handleAndConditions(
                  touched.userName,
                  errors.userName,
                  <Typography className={classes.errorMsg}>
                    {errors.userName}
                  </Typography>
                )}
              </Box>
              <Button
                data-test-id="stepTwoButton"
                onClick={() => handleSubmit()}
                className={handleOrConditions(
                  values.userName,
                  classes.buttonPrimary,
                  handleOrConditions(this.state.themeSwitch, classes.buttonYellowDisable,classes.buttonDisable)
                )}
              >
                {configJSON.continueLabel}
              </Button>
            </>
          )}
        </Formik>
      );
    };

    

    const StepThree = () => {
      return (
        <Formik
          initialValues={{
            code: "US",
            phoneNumber: ""
          }}
          data-test-id="stepThirdForm"
          validationSchema={validation_Schema_stepThree}
          onSubmit={values => this.senOtpToUser(values)}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <Box className={classes.backIconBox}>
                <CustomBackButton
                  data-test-id="backToStepTwo"
                  handleClick={this.redirectToSecondPage}
                />
              </Box>

              <Typography align="center" className={classes.centerTextSU1}>
                {this.state.thoughts}
              </Typography>
              <Box>
                <Typography
                  data-test-id="thirdStepText"
                  className={classes.lockTextSU}
                >
                  {configJSON.securityMessage}
                </Typography>
                <Box className={classes.flexDisplaySU}>
                  <Box className={classes.marginAutoSU}>
                    <Select
                      name="code"
                      disableUnderline
                      className={classes.countryCodeSelect}
                      renderValue={(value: unknown) => (
                        <>
                          <span
                            className={`country-flag-outer fi fi-${values.code.toLocaleLowerCase()}`}
                          />
                          {this.getOuterCountryLabel(value as string)}
                        </>
                      )}
                      value={values.code}
                      onChange={handleChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        classes: {
                          paper: classes.countryCodeMenu
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {configJSON.countryCodes.map(
                        (countryInfo: ICountryCode) => {
                          const { label, code } = countryInfo;
                          const [firstPart, ...rest] = label.split(" ");
                          const secondPart = rest.join(" ");

                          return (
                            <MenuItem key={code} value={code}>
                              <span
                                className={`country-flag fi fi-${code.toLowerCase()}`}
                              />
                              <span className="country-name">{firstPart}</span>
                              <span className="country-dial">{secondPart}</span>
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    <Box className={classes.phoneNumberWrapper}>
                      <Box className={classes.separator} />
                      <TextField
                        name="phoneNumber"
                        data-test-id="phone-number-field"
                        className={classes.phoneNumber}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onKeyPress={this.handleKeyPress}
                        placeholder="Add Your Mobile Number"
                        InputProps={{
                          disableUnderline: true
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Box>
                  </Box>
                </Box>
                <ErrorMessage
                  component="p"
                  name="phoneNumber"
                  className={classes.errorMsg}
                />
                <Box className={classes.flexDisplaySU}>
                  <img
                    src={lockIcon}
                    alt="lockIcon"
                    className={classes.lockIcon}
                  />
                  <Typography className={classes.lockTextSU}>
                    {configJSON.protectCommunityMessage}
                  </Typography>
                </Box>
                {handleOrConditions(
                  this.state.phoneNumberError,
                  <Typography className={classes.errorMsg}>
                    {configJSON.phoneNumberErrorMessage}
                  </Typography>,
                  null
                )}
              </Box>

              <Box>
                <Button
                  data-test-id="stepThreeSubmit"
                  onClick={() => handleSubmit()}
                  className={handleOrConditions(
                    values.phoneNumber,
                    classes.buttonPrimaryThree,
                    handleOrConditions(this.state.themeSwitch, classes.buttonYellowDisable,classes.buttonDisable)
                  )}
                  
                >
                  {configJSON.continueLabel}
                </Button>
                <Typography className={classes.lockTextSU}>
                  {configJSON.termsConditionsMessage}
                </Typography>
              </Box>
            </>
          )}
        </Formik>
      );
    };

    const handleSteps = () => {
      if (this.state.stepOne) {
        return null;
      } else if (this.state.stepTwo) {
        return <StepTwo data-test-id="mainContainer2" />;
      } else {
        return <StepThree data-test-id="mainContainer3" />;
      }
    };

    return (
      <ThemeProvider theme={this.state.themeSwitch ? themeYellow : themeWhite}>
        <MobileLoader loading={this.state.loading} />
        <CssBaseline />
        <Container
          data-test-id="mainContainer"
          maxWidth="sm"
          className={classes.mainContainer}
          style={
            this.state.isKeyboardOpen
              ? { gap: "50px" }
              : { justifyContent: "space-between" }
          }
        >
          {this.state.stepOne && (
            <Formik
              initialValues={{
                thoughts: ""
              }}
              validationSchema={validation_Schema_stepOne}
              onSubmit={values => this.handleThoughtSubmit(values)}
              data-test-id="stepOneForm"
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit
              }) => (
                <>
                  <Box
                    className={classes.iconContainer}
                    data-test-id="iconContainer"
                  >
                    <Box className={classes.backIconBox}>
                      <CustomBackButton
                        dataTestId="backToStepOne"
                        handleClick={this.handleSignInClick}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        data-test-id="themeSwicthSignup"
                        control={
                          <IOSSwitch
                            // @ts-ignore
                            checked={this.state.themeSwitch}
                            onChange={this.handelThemeChange}
                            name="checkedB"
                          />
                        }
                        label=""
                        className={classes.toggleLabel}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <textarea
                      rows={4}
                      name="userThoughts"
                      data-test-id="stepOneText"
                      className={classes.thoughtsInput}
                      placeholder={configJSON.enterThoughtsPlaceholder}
                      value={values.thoughts}
                      onChange={handleChange("thoughts")}
                      onFocus={this.handleFocus}
                      onBlur={() => {
                        handleBlur("thoughts");
                      }}
                    />
                    {handleAndConditions(
                      touched.thoughts,
                      errors.thoughts,
                      <Typography className={classes.errorMsg}>
                        {errors.thoughts}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    data-test-id="stepOneButton"
                    onClick={() => handleSubmit()}
                    className={handleOrConditions(
                      values.thoughts,
                      classes.buttonPrimary,
                      handleOrConditions(this.state.themeSwitch, classes.buttonYellowDisable,classes.buttonDisable)
                    )}
                  >
                    {configJSON.continueLabel}
                  </Button>
                  <Typography className={classes.signInContainer}>
                    {configJSON.alreadyHaveAnAccountMessage}
                    <span
                      data-test-id="eu-redirect-signin-btn"
                      onClick={this.handleSignInClick}
                    >
                      {configJSON.signInLabel}
                    </span>
                  </Typography>
                </>
              )}
            </Formik>
          )}
          {handleSteps()}
        </Container>
      </ThemeProvider>
    );
  }
}

const styles = () =>
  createStyles({
    addNameBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    iconContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    errorMsg: {
      ...commonStyles.errorMsg,
      margin: "3px 0px 0px"
    },
    nameInputSU: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      outline: "none",
      background: "transparent",
      padding: "5px 0px",
      margin: "10px 0 4px",
      border: "none",
      fontFamily: "Inter, 'sans-serif'",
      borderBottom: "1px solid #000",
      "&::placeholder": {
        color: "#7f6415",
        fontFamily: "Inter, 'sans-serif'"
      }
    },
    marginAutoSU: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      "& .country-flag-outer": {
        marginRight: "5px",
        fontSize: "20px",
        borderRadius: "2px"
      }
    },
    countryCodeSelect: {
      "& .MuiSelect-select": {
        padding: "16px 0px",
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'",
        display: "flex",
        alignItems: "center",
        "&:focus": {
          backgroundColor: "transparent"
        }
      },
      "& .MuiSelect-icon": {
        display: "none"
      }
    },
    phoneNumberWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      paddingLeft: "12px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
    },
    separator: {
      height: "26px",
      width: "1px",
      backgroundColor: "#6F6F70"
    },
    phoneNumber: {
      width: "100%",
      "& input": {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Inter, 'sans-serif'",
        padding: "16px 12px 16px 3px",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.5)"
        }
      }
    },
    countryCodeMenu: {
      maxHeight: "260px",
      borderRadius: "7px",
      "& > ul": {
        padding: "0",
        "& li": {
          fontFamily: "Inter, 'sans-serif'",
          padding: "12px 9px",
          fontSize: "15px",
          "&:hover": {
            backgroundColor: "#f1f1f1"
          },
          "& .country-flag": {
            fontSize: "18px",
            borderRadius: "2px",
            marginRight: "5px"
          },
          "& .country-dial": {
            color: "#6b6b6b",
            marginLeft: "5px"
          }
        }
      }
    },
    flexDisplaySU: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px"
    },
    lockIcon: {
      height: "16px",
      width: "16px",
      marginRight: "5px"
    },
    lockTextSU: {
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      fontFamily: "Inter, 'sans-serif'"
    },
    textOne: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "14px",
      padding: "35px 0px 0px",
      fontFamily: "Inter, 'sans-serif'"
    },
    textTwoSU: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "14px",
      fontFamily: "Inter, 'sans-serif'"
    },
    centerTextSU: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      paddingLeft: "20px",
      paddingRight: "20px"
    },
    centerTextSU1: {
      color: "#000",
      textAlign: "center",
      alignItems: "center",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      margin: "0 auto",
      fontFamily: "Inter, 'sans-serif'",
      // maxHeight: "170px",
      overflowY: "auto",
      overflow: "visible", /* Ensures no scrolling occurs */
  height: "auto",      /* Adjust height based on content */
  maxHeight: "none"  /* Removes any maximum height limit */
    },
    centerTextSU2: {
      color: "#000",
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
      paddingLeft: "10px",
      paddingRight: "35px",
      overflow: "scroll",
      position: "absolute",
      top: "50px"
    },
    mainContainer: {
      ...commonStyles.mobileContainer,
      padding: "30px 20px",
      overflowY: "auto",
      maxWidth: "450px",
    },
    buttonDisable: {
      ...commonStyles.mobileButton,
      width: "100%",
      alignSelf: "center",
      height: "54px",
      color: "#666",
      backgroundColor: "#cccccc",
      marginBottom: "70px",
      "&:hover": {
        color: "#666",
        backgroundColor: "#cccccc"
      }
    },
    buttonYellowDisable: {
      ...commonStyles.mobileButton,
      backgroundColor: "#7f6415",
      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#7f6415",
      },
    },
    buttonPrimary: {
      ...commonStyles.mobileButton,
      width: "100%",
      alignSelf: "center",
      height: "56px",
      backgroundColor: "#000000",
      marginBottom: "70px"
    },
    buttonPrimaryThree:{
      ...commonStyles.mobileButton,
      width: "100%",
      alignSelf: "center",
      height: "56px",
      backgroundColor: "#000000",
    marginBottom: "20px"
    },
    buttonPrimarySignUp: {
      ...commonStyles.mobileButton,
      marginBottom: "20px",
      width: "80%",
      alignSelf: "center",
      height: "56px",
      backgroundColor: "#7f6415"
    },
    backIconBox: {
      margin: "10px 0",
      position: "relative",
      right: "20px"
    },
    toggleLabel: {
      marginRight: "0px",
      "& span": {
        margin: "0px"
      }
    },
    thoughtsInput: {
      background: "transparent",
      border: "none",
      outline: "none",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      fontFamily: "Inter",
      width: "100%",
      textAlign: "center",
      color: "#000",
      "&::placeholder": {
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center",
        color: "#000",
        opacity: 1
      },
      "&:focus::placeholder": {
        opacity: 0
      }
    },
    signInContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginTop: "30px",
      color: "#0F172A",
      lineHeight: "24px",
      fontFamily: "Inter",
      fontWeight: 400,
      "&>span": {
        fontWeight: "700",
        lineHeight: "24px",
        fontFamily: "Inter",
        cursor: "pointer"
      }
    },
    secondStepMainBox: {
      overflow:"visible",
      height:"auto",
      maxHeight:"none"
    }
    
  });

export default withStyles(styles)(EndUserEmailSignUp);
// Customizable Area End
