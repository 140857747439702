// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getToken } from "../../../components/src/AuthService";
import { InstagramMediaProductTypes, InstagramMediaTypes } from "./enums";
import { File } from "./types";
import React from "react";
export const configJSON = require("./config");

interface IInstagramMedia {
  id: string;
  caption: string;
  media_type: InstagramMediaTypes;
  media_url: string;
  permalink: string;
  thumbnail_url: string | null;
  timestamp: string;
  media_product_type: InstagramMediaProductTypes;
}

interface IPostSuccessResponse {
  data: IInstagramMedia[]
  paging: {
    cursors: {
      before: string
      after: string
    }
    next?: string
    previous?: string
  }
}

interface IPostErrorResponse {
  errors: string
}

export interface Props {
  navigation: any;
  handleGoBack: () => void;
  setFiles: (files: File[]) => void;
}

interface S {
  isLoading: boolean;
  paginationLoader: boolean
  apiError: string;
  instagramMedia: IInstagramMedia[];
  nextCursor: string
  hasMore: boolean
  selectedMediaIds: string[];
}

interface SS {
  navigation: any;
}

export default class EverybrandLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getInstagramMediaApiCallId: string = "";
  dialogContentRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.dialogContentRef = React.createRef<HTMLDivElement>()

    this.state = {
      isLoading: false,
      paginationLoader: false,
      apiError: "",
      instagramMedia: [],
      nextCursor: "",
      hasMore: false,
      selectedMediaIds: [],
    };
  }

  async componentDidMount() {
    this.getInstagramMedia();
    this.addEventListenerToDialogContent()
  }

  async componentWillUnmount(): Promise<void> {
    this.removeEventListenerToDialogContent()
  }

  addEventListenerToDialogContent = () => {
    if (this.dialogContentRef.current) {
      this.dialogContentRef.current.addEventListener(
        "scroll",
        this.handleDialogScroll
      );
    }
  }

  removeEventListenerToDialogContent = () => {
    if (this.dialogContentRef.current) {
      this.dialogContentRef.current.removeEventListener(
        "scroll",
        this.handleDialogScroll
      );
    }
  }

  handleDialogScroll = () => {
    const { nextCursor, isLoading, paginationLoader, hasMore } = this.state
    const element = this.dialogContentRef.current;
    if (
      element &&
      element.scrollTop + element.clientHeight >= element.scrollHeight - 600 &&
      hasMore &&
      !isLoading &&
      !paginationLoader
    ) {
      this.getInstagramMedia(nextCursor);
    }
  }

  handleAcceptFiles = () => {
    const { instagramMedia, selectedMediaIds } = this.state;
    const selectedMediaList = [...instagramMedia].filter((media) =>
      selectedMediaIds.includes(media.id)
    );
    const uploadFiles = selectedMediaList.map((file) => {
      const { media_url, id, media_type, thumbnail_url } = file;

      return {
        filePreview: thumbnail_url || "",
        preview: media_url,
        name: `Instagram_${id}`,
        type: media_type.toLocaleLowerCase()
      };
    });
    this.props.setFiles(uploadFiles);
  };

  toggleSelectionMedia = (selectedMediaId: string) => {
    let currentSelectedMediaIds = [...this.state.selectedMediaIds];
    const isAlreadySelected = currentSelectedMediaIds.includes(selectedMediaId);
    if (isAlreadySelected) {
      currentSelectedMediaIds = [...currentSelectedMediaIds].filter(
        (mediaId) => mediaId !== selectedMediaId
      );
    } else {
      currentSelectedMediaIds.push(selectedMediaId);
    }
    this.setState({ selectedMediaIds: currentSelectedMediaIds });
  };

  initiateInstagramLogin = () => {
    const clientId = configJSON.INSTAGRAM_APP_ID;
    const redirectUri = `${window.location.origin}/${configJSON.instagramAuthRoute}`;
    const authUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish`;

    window.open(authUrl, "_self");
  };

  handleGetMediaAPIResponse = (responseJson: IPostSuccessResponse | IPostErrorResponse) => {
    if ("errors" in responseJson) {
      let errorMessage = responseJson.errors;
      if (errorMessage === "User doesn't have a token") {
        errorMessage = configJSON.instagramAccountNotConnectedMessage;
        setTimeout(() => {
          this.initiateInstagramLogin();
        }, 3000);
      }
      this.setState({
        apiError: errorMessage || "Something went wrong",
        isLoading: false,
        paginationLoader: false
      });
    } else {
      const { data, paging: { cursors: { after }, next } } = responseJson
      const { instagramMedia } = this.state
      this.setState({
        instagramMedia: [...instagramMedia, ...data],
        isLoading: false,
        paginationLoader: false,
        nextCursor: after,
        hasMore: !!next
      });
    }
  };

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getInstagramMediaApiCallId) {
      this.handleGetMediaAPIResponse(responseJson);
    }
  }

  getInstagramMedia = (nextCursor?: string) => {
    let endpoint = configJSON.instagramMediaApiEndPoint
    if(nextCursor) {
      this.setState({ paginationLoader: true })
      endpoint = `${endpoint}?after=${nextCursor}`
    } else {
      this.setState({ isLoading: true })
    }

    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: getToken(),
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getInstagramMediaApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId
  };
}
// Customizable Area End
