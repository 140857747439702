// Customizable Area Start
import React from "react";
import TikTokAuthRedirectController from "./TikTokAuthRedirectController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CustomAlert from "../../../components/src/CustomAlert.web";

class TikTokAuthRedirect extends TikTokAuthRedirectController {
  render() {
    const { isLoading, alertMessage, alertType } = this.state;

    return (
      <>
        <CustomAlert alertType={alertType} message={alertMessage} />
        <CustomLoader isLoading={isLoading} wrapperHeight="70vh" />;
      </>
    );
  }
}

export default TikTokAuthRedirect;
// Customizable Area End
