// Customizable Area Start
import React, { CSSProperties } from "react";
import { Box, Typography } from "@material-ui/core";
import { configJSON } from "./EmailAccountRegistrationController.web";
import StripeForm from "./StripeForm.web";
import { Elements } from "@stripe/react-stripe-js";
import PaymentDetailsController, {
  stripePromise,
} from "./PaymentDetailsController.web";
import CustomBackButton from "../../../components/src/CustomBackButton.web";

const webStyles: Record<string, CSSProperties> = {
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "66px",
  },
  paymentDetailWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "45%",
    minWidth: "380px",
  },
  title: {
    fontWeight: 700,
    fontFamily: "Inter, 'sans-serif'"
  },

  planDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: "3px",
    flexWrap: "wrap",
  },

  boldText: {
    fontWeight: 700,
  },

  priceText: {
    fontSize: "36px",
  },
};

class PaymentDetails extends PaymentDetailsController {
  render() {
    const { attributeName, paymentPrize } = this.state;

    const { platformManagementChecked, handleGoBackClick } = this.props;

    const stripeOptions = {
      clientSecret: this.props.stripePaymentData.attributes.client_secret,
    };

    return (
      <>
        <Box
          style={webStyles.paymentDetailWrapper}
          data-test-id="paymentDetails"
        >
          <Box style={webStyles.headingWrapper}>
            <CustomBackButton handleClick={handleGoBackClick} />
            <Typography
              variant="h6"
              style={webStyles.title}
              data-test-id="description"
            >
              {configJSON.paymentDetails}
            </Typography>
          </Box>
          <Box style={webStyles.planDetails}>
            <Typography
              variant="h6"
              style={webStyles.boldText}
              data-test-id="planName"
            >
              {configJSON.plan} {attributeName}
            </Typography>
            {configJSON.perMonth && (
              <Typography
                data-test-id="planPrice"
                variant="h6"
                style={webStyles.boldText}
              >
                {configJSON.$}
                <span style={webStyles.priceText}>{platformManagementChecked ? Number(paymentPrize) + this.getPlatformManagementPrice() : paymentPrize}</span>
                {configJSON.perMonth}
              </Typography>
            )}
          </Box>

          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeForm
              goToBack={this.props.handleGoBackClick}
              handleSubmit={this.handleSubmit}
            />
          </Elements>
        </Box>
      </>
    );
  }
}

export default PaymentDetails;
// Customizable Area End
