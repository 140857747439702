import React, { useEffect, useRef } from 'react';
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button, Box, Paper, Typography, IconButton, } from '@material-ui/core';
import { NavLink, Link } from "react-router-dom";
import { routes } from './constansts';
import { HomeIcon, ContactsIcon, SettingsIcon, addIcon, logoEverybrand,imageVector } from '../../blocks/dashboard/src/assets';
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

const navigationMenus = [
  { name: "home", icon: HomeIcon, path: routes.eudashboard },
  { name: "trending",icon: imageVector, path: routes.eutrending },
  { name: "create", icon: addIcon, path: routes.eucreate },
  { name: "profile", icon: ContactsIcon, path: routes.euprofile },
  { name: "notifications", path: routes.eunotification },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      // backgroundColor: theme.palette.background.paper,
      backgroundColor: "#FFFFFF",
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    toolbar: {
      display: 'flex',
      position : "relative",
      gap: '10%',
      justifyContent: "center",
      alignItems: "center",
    },

    logo: {
      position: "absolute",
      top: "3px",
      left: "15px",
      height: 55
    },
    navButton: {
      fontWeight: 700,
      padding: "10px",
      fontSize: "16px",
      fontStyle: "normal",
      fontFamily: "Inter",
      color: "#000000"
    },
    navigationContainer: {
      display: "flex",
      gap: "30px"
    },
    active: {
      "& .MuiButton-label": {
        borderBottom: '3px solid  #FFC629'
      },

    },
    activeUsersContainer: {
      padding: theme.spacing(2, 1),
      background: "#f1f4f9"
    },
    heading: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    brandsUlContainer: {
      display: "flex",
      listStyle: "none",
      alignItems: "center",
      margin: "0px",
      padding: "10px 10px",
    },
    brandsLiContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      justifyContent: 'space-between',
      alignItems: "center",
      margin: "15px",
    },
    brandsImageContainer: {
      width: "82px",
      height: "85px",
      background: "#FFC629",
      border: "2px solid #FFC629",
      borderRadius: "50%",
    },
    arrowIconContainer: {
      width: "24px",
      height: "24px",
      textAlign: "center",
      borderRadius: "50%",
      padding: "5px",
      border: "1px solid #000",
      marginBottom: "15px",
    },
    swiperContainer: {
      position: "relative",
      "& .swiper-wrapper": {
        height: "auto"
      }
    },
    swiperButton: {
      position: "absolute",
      top: "32%",
      transform: "translateY(-50%)",
      zIndex: 10,
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& .swiper-button-next ": {
        border: "2px solid",
        padding: "18px",
      },
      "& .swiper-button-next::after": {
        fontSize:"18px",
        fontWeight: "600"
      },
      "& .swiper-button-prev": {
        border: "2px solid",
        padding: "18px",
      },
      "& .swiper-button-prev::after": {
        fontSize:"18px",
      fontWeight: "600"
      }
    },
  })
);

interface BrandAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  cover_photo: {
    url : string
  } | null;
  follows: {
      followers: string;
      followings: string;
  };
  subscription_paused: boolean;
}
interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

interface BrandsIFollowProps {
	brands?: Brand[] | null;
}

const NavigationMenuEu: React.FC<BrandsIFollowProps> = ({brands = null}) => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky" className={classes.appBar} style={{marginBottom: brands?.length == 0 ? "50px" : "auto"}}>
        <Toolbar className={classes.toolbar}>
          <img src={logoEverybrand} alt="Everybrand logo" className={classes.logo} />
          <div className={classes.navigationContainer}>
            {navigationMenus && navigationMenus.length > 0
              ? navigationMenus.map((menu) => (
                <NavLink
                  key={menu.name}
                  to={menu.path}
                  activeClassName={classes.active}
                >
                  <Button
                    className={classes.navButton}
                  >
                    {
                      menu.name === "notifications" ? <NotificationsOutlinedIcon style={{
                        marginRight: "20px"
                      }} /> : <img src={menu.icon} alt=""
                        style={{ marginRight: "20px" }} />
                    }

                    {menu.name}
                  </Button>
                </NavLink>
              ))
              : null}
          </div>
        </Toolbar>
      </AppBar>
      {(brands && brands?.length > 0) &&  <Paper style={{ padding: "0px 180px 0px 100px", margin: "10px 20px 20px" }} square elevation={0} className={classes.activeUsersContainer}>
      <Typography gutterBottom variant="subtitle2" className={classes.heading}>
        Brands I Follow
      </Typography>
      <Box className={classes.swiperContainer}>
        <div className={classes.swiperButton} style={{left: "-50px"}}
        >
          <IconButton className={`swiper-button-prev`} id="swiper-button-prev"></IconButton>
        </div>
        <Swiper
          spaceBetween={4}
          slidesPerView={10}
          navigation={{
            prevEl: '#swiper-button-prev',
            nextEl: '#swiper-button-next',
          }}
          breakpoints={ {
            320: {
              slidesPerView: 3,
            },
            768: {
                slidesPerView: 4,
            },
            991: {
                slidesPerView: 6,
            },
            1024: {
                slidesPerView: 6,
            },
            1199: {
              slidesPerView: 7,
            },
            1250: {
              slidesPerView: 7,
            },
            1366: {
              slidesPerView: 9,
            }
          }}
        >
          {brands && brands.map((brand, index) => (
            <SwiperSlide key={index} >
              <Box className={classes.brandsLiContainer}>
                <Link to={`/profile/${brand.id}`}>
                <Box className={classes.brandsImageContainer}>
                  <img src={brand.attributes.profile_pic?.url || `https://avatar.iran.liara.run/public/${index+1}`} alt={brand.attributes.first_name || ""} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                </Box>
                </Link>
                <Typography style={{ fontWeight: 600, fontSize: "12px" }}>{`${brand.attributes.first_name} ${brand.attributes.last_name}`}</Typography>
              </Box>
            </SwiperSlide>
          ))
          }
        </Swiper>
        <div className={classes.swiperButton}  style={{right: "-50px"}}>
          <IconButton id="swiper-button-next" className={`swiper-button-next`}></IconButton>
        </div>
      </Box>
    </Paper>}
    </>
  );
};

export default NavigationMenuEu;