import React from 'react';
import { IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface SettingsButtonProps {
}

const SettingsButton: React.FC<SettingsButtonProps> = () => {
  return (
    <div style={styles.button}>
      <IconButton style={{ color: '#FFFFFF' }}>
        <MoreHorizIcon />
      </IconButton>
    </div>
  );
};

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "absolute",
    bottom: 20, 
    right: 17,
    zIndex: 5
  } as React.CSSProperties
};

export default SettingsButton;
