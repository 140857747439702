// Customizable Area Start
import React, { Component } from 'react';
import { styled } from '@material-ui/core/styles';

interface DescriptionComponentProps {
  description?: string;
  dataTestId?: string
  moreBtnText?: string
}

interface DescriptionComponentState {
  isExpanded: boolean;
}

const Container = styled('div')({
  position: 'relative',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  wordBreak: 'break-word',
  maxHeight: "60px",
  overflowY: "auto",
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none', 
});

const Text = styled('span')({
  display: 'inline',
  marginRight: '3px',
});

const ReadMoreButton = styled('button')({
  display: 'inline',
  fontWeight: 700,
  color: '#FFF',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  fontSize: '14px',
});

class DescriptionComponent extends Component<
  DescriptionComponentProps,
  DescriptionComponentState
> {
  constructor(props: DescriptionComponentProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toggleExpansion = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    const { description, dataTestId } = this.props;
    const { isExpanded } = this.state;

    const maxLength = 40;
    const isLongDescription = description && description.length > maxLength;
    const truncatedDescription = isLongDescription
      ? description?.substring(0, maxLength)
      : description;

    return (
      <Container>
        <Text data-test-id={dataTestId}>
          {isExpanded || !isLongDescription
            ? description
            : truncatedDescription}
        </Text>
        {isLongDescription && (
          <ReadMoreButton onClick={this.toggleExpansion}>
            {isExpanded ? 'less' : 'more...'}
          </ReadMoreButton>
        )}
      </Container>
    );
  }
}

export default DescriptionComponent;
// Customizable Area End
