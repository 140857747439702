// Customizable Area Start
import React, { Component } from "react";
import { styled } from "@material-ui/core/styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

const Wrapper = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
})

const VideoContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundColor: "#000",
  borderRadius: "8px",
  overflow: "hidden",
  cursor: "pointer",
});

const CustomPlayPauseButton = styled("div")({
  position: "absolute",
  bottom: "10px",
  left: "10px",
  backgroundColor: "#eebe40",
  color: "#000",
  padding: "8px",
  borderRadius: "50%",
  cursor: "pointer",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 3,
});

const VideoElement = styled("video")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  zIndex: 1,
});

interface IProps {
  src: string;
}

interface S {
  isPlaying: boolean;
}

class SocialVideoElement extends Component<IProps, S> {
  private videoRef: React.RefObject<HTMLVideoElement>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      isPlaying: false,
    };
    this.videoRef = React.createRef();
  }

  togglePlayPause = (event: React.MouseEvent) => {
    event.stopPropagation()
    const video = this.videoRef.current;

    if (video) {
      if (this.state.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    }

    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
    }));
  };

  render() {
    const { src } = this.props;
    const { isPlaying } = this.state;

    return (
      <Wrapper>
        <VideoContainer>
          <VideoElement ref={this.videoRef} src={src} />
          <CustomPlayPauseButton onClick={this.togglePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </CustomPlayPauseButton>
        </VideoContainer>
      </Wrapper>
    );
  }
}

export default SocialVideoElement;
// Customizable Area End
