import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Avatar, Box, Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import CheckIcon from '@material-ui/icons/Check';
import TopActiveUsers from '../../dashboard/src/TopActiverUsers.web';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import PostCreationCommonController from './PostCreationCommonController';
import CustomLoader from '../../../components/src/CustomLoader.web';
import { styled } from "@material-ui/core/styles";

const CreateWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent:"center",
  flexDirection: "column",
})

const CreateInnerWrapper = styled(Box)({
  height: "200px",
  width: "200px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})


export default class PostCreationEu extends PostCreationCommonController {

  avatarRender(profile: {url : string} | null , index: number) {
    return (<Avatar src={profile ? profile.url : `https://avatar.iran.liara.run/public/${index+1}`} style={index < this.state.brands.length ? webStyle.brandLogo : webStyle.brandLogoInactive} />)
  }

  render() {
    const { navigation } = this.props
    const { top6CreatePost } = this.state

    return (
      <div style={webStyle.root}>
        <NavigationMenuEUWeb brands={this.state.brandsIFollowCreate}/>
        <Box style={webStyle.mainContainer}>
          <Grid container style={webStyle.gridContainer} >
            <Grid item xs={6} md={8} style={webStyle.gridItem} >
              {
                this.state.eupoststep > 1 ? <Box data-test-id="back-button" style={webStyle.arrowIconContainer} onClick={this.SetEuPoststep.bind(this,this.state.eupoststep-1)}>
                  <ArrowBackIosOutlinedIcon />
                </Box> : ''
              }

              <Box style={webStyle.contentBox}>

                <Typography style={webStyle.fontinfo}>
                  Where would you like to post?
                </Typography>
                <Box style={webStyle.searchBox}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={this.state.eupoststep === 1 ? "Search" : `Selected: dfjakjfdjjerahf jfhj ${this.state.selectedBrand.map(elem => elem.name).join(" - ")}`}
                    style={webStyle.searchInput}
                    disabled={this.state.eupoststep === 2}
                    InputProps={{
                      style: { borderRadius: '98px' },
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            this.state.selectedBrand.length === 0 ? <SearchIcon style={webStyle.searchIcon} /> : ''
                          }

                        </InputAdornment>
                      ),
                      // classes: { input: webStyle.textFieldPadding }
                    }}
                    data-test-id="txtInputSearch"
                    onChange={(e)=>this.SearchValue(e.target.value)}
                  />

                </Box>
                <Box>
                {
                  this.state.euCreateLoading ? <CustomLoader loaderSize={24} isLoading={this.state.euCreateLoading} /> :
                  <>
                {
                  this.state.eupoststep === 1 && <Grid container spacing={2} style={webStyle.brandGrid}>
                    {
                      [...this.state.brands, ...this.state.suggestedBrandsCreate].length > 0 ? 
                      <>
                        {
                        [...this.state.brands, ...this.state.suggestedBrandsCreate].map((brand, index) => {
                          const name = brand.attributes.first_name + " " + brand.attributes.last_name
                          const profile = brand.attributes.profile_pic
                          return(
                          <Grid item xs={3} key={index}>
                            <Box style={webStyle.brandBox}
                              onClick={this.handleBrandSelection.bind(this, {id: brand.id ,name}, index)}
                            >
                              {this.avatarRender(profile, index)}
                              <Typography style={webStyle.brandName}>{name}</Typography>
                              {this.state.selectedBrand.some(elem => elem.id === brand.id) && <Box style={{width: "100%", height: "100%", position: "absolute", top: "25%", left: "42%"}}><CheckIcon style={{fontSize:"30px"}}/></Box>}
                            </Box>
                          </Grid>
                        )})}
                        <Grid item xs={12} lg={12} style={webStyle.continueWrapper}>
                        <Button 
                          variant="contained" 
                          data-test-id="continue-button" 
                          style={webStyle.continue} 
                          onClick={this.handleContinue.bind(this)}
                          disabled={this.state.selectedBrand.length === 0}
                        >
                          <CheckIcon style={{marginRight:"8px"}}/> Continue
                        </Button>
                        </Grid>
                      </>
                        :
                        <Box>
                          Can't find any data
                        </Box>
                    }
                  </Grid>
                }
                {
                  this.state.eupoststep === 2 &&
                  // 
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={6}>
                      <CreateWrapper>
                        <CreateInnerWrapper style={webStyle.createPostInnerWrapper}>
                          <WallpaperIcon style={{fontSize: "55px"}}/>
                        </CreateInnerWrapper>
                        <Typography data-test-id="create-new-post" variant='h6' style={webStyle.createPostText}>
                          Create New Post
                        </Typography>
                      </CreateWrapper>
                    </Grid>
                    <Grid item xs={6}>
                      <CreateWrapper>
                        <CreateInnerWrapper style={webStyle.leaveReviewContainer}>
                          <Rating
                            name="size-large" 
                            defaultValue={2}
                            size='large'
                            value={this.state.starRating}
                            style={{ color: "#FFC629" }}
                            data-test-id="RatingChange"
                            onChange={(event, newValue) => {
                              this.ChangeRating(newValue);
                            }}
                          />
                        </CreateInnerWrapper>
                        <Typography variant='h6' style={webStyle.createPostText}>
                          Leave a Review
                        </Typography>
                      </CreateWrapper>
                      {this.state.reviewResult && (
    <Typography style={{ color: "red" }}>
      {this.state.reviewResult}
    </Typography>
  )}
                   {this.state.reviewMessage && (
    <Typography style={{ color: "black",display:"flex",justifyContent:"center",width:"100%" }}>
      {this.state.reviewMessage}
    </Typography>
  )}
                    </Grid>
                  </Grid>

                }
                </>
              }
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3} style={{...webStyle.gridItem,maxHeight: "700px"}} >
              <Box>
                <TopActiveUsers
                  top6={top6CreatePost}
                  navigation={navigation}
                />
              </Box>
              <Box>
                <SuggestedBrands
                  brands={this.state.suggestedBrandsCreate}
                  followBrand={this.followBrandPostApi}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}


const webStyle = {
  root: {
    backgroundColor: "#f1f4f9",
    minHeight: "100vh"
  },
  mainContainer: {
    width: "100vw",
    margin: "15px auto",
    maxWidth: "1240px"
  },
  gridContainer: {
    marginTop: '10px',
    gap: '15px',
    backgroundColor: "#f1f4f9",
    justifyContent: "center"
  },
  gridItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: '4px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  contentBox: {
    margin: "35px 15px 20px 50px",
  },
  fontinfo: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: '16px',
    borderBottom: "1px solid #64748B33",
    paddingBottom: '16px'
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '24px',
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  searchInput: {
      background: "#F7F8FC",
      marginTop: "13px"
  },
  textFieldPadding: {
    padding: "10px"
  },
  brandGrid: {
    marginTop: '16px',
  },
  brandBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: "relative"
  } as React.CSSProperties,
  brandLogo: {
    width: '64px',
    height: '64px',
    border: `2px solid #FFC629`,
    padding: '8px',
    cursor: "pointer"
  },
  brandLogoInactive: {
    width: '64px',
    height: '64px',
    border: `2px solid grey`,
    padding: '8px',
    cursor: "pointer",
    opacity: "0.5",
    filter: "grayscale(80%)",
    transition: "opacity 0.3s ease, filter 0.3s ease"
  },
  brandName: {
    marginTop: '8px',
    textAlign: 'center',
    color: "#334155",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: '16px',
  } as React.CSSProperties,
  continue: {
    backgroundColor: "#FFC629",
    textTransform: "capitalize" as "capitalize",
    padding: "10px 16px",
    width: "132px",
    borderRadius: "8px",
    gap: "8px",
    boxShadow: "none",
    justifyContent: "flex-start",
    "& span": {
      fontSize: "16px",
      fontWeight: 700,
      fontFamily: "Inter, 'sans-serif'"
    },
    "&:hover": {
      backgroundColor: "#FFC629",
      boxShadow: "none",
    }
  },
  continueWrapper:{
    display:"flex",
    justifyContent: "flex-end",
    padding: "30px 60px 10px"
  },
  createPostInnerWrapper: {
    backgroundColor: "#FFC629",
  },
  leaveReviewContainer: {
    backgroundColor: "#000000",
  } as React.CSSProperties,
  createPostText: {
    color: "#000000",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "32px",
    fontSize: "18px",
    marginTop: "10px"
  },
  arrowIconContainer: {
    width: "24px",
    height: "24px",
    textAlign: "center",
    borderRadius: "50%",
    padding: "5px",
    border: "1px solid #000",
    margin: "39px 0 0 31px",
    cursor:"pointer"
  } as React.CSSProperties
}