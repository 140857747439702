// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  tutorialModal: boolean
  likesCount: number
  commentCount: number
  reviewsCount: number
  profilePic: string
  widgetCode: string
  isLoading: boolean
  alertMessage: string
  alertType: "success" | "error"
}

interface SS {
  id: any;
}
export default class ConnectWebsiteController extends BlockComponent<
  Props,
  S,
  SS
> {
  alertTime: NodeJS.Timeout | null = null
  getWidgetDataId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      tutorialModal: false,
      likesCount: 0,
      commentCount: 0,
      reviewsCount: 0,
      profilePic: "",
      widgetCode:"",
      isLoading: false,
      alertMessage: "",
      alertType: "success"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentWillUnmount(): Promise<void> {
    if(this.alertTime) {
      clearTimeout(this.alertTime)
    }
  }

  showAlert = (alertType: "success" | "error", alertMessage: string) => {
    this.setState({ alertType, alertMessage })

    this.alertTime = setTimeout(() => {
      this.closeAlert()
    }, 3000)
  }

  closeAlert = () => {
    this.setState({ alertType: "success", alertMessage: "" })
  }

  closeTutorialModal = () => {
    this.setState({ tutorialModal: false });
  };

  openTutorialModal = () => {
    this.setState({ tutorialModal: true });
  };

  async componentDidMount(): Promise<void> {
    this.getEmbedData();
  }

  async receive(_from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getWidgetDataId) {
        this.handleEmbedDataResponse(responseJson)
      }
    }
  }

  handleEmbedDataResponse(response: any) {
    this.setState({
      widgetCode:response.widget_code,
      likesCount: response.likes_count,
      commentCount: response.comments_count,
      reviewsCount: response.average_review_rating,
      profilePic: response.account.data.attributes.profile_pic?.url || "",
      isLoading: false
    })
  }

  handleCopy = () => {
    navigator.clipboard.writeText(this.state.widgetCode)
    this.showAlert("success", "Code copied!")
  }

  getEmbedData() {
    this.setState({ isLoading: true })

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      Token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWidgetDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmbedDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
