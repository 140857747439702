// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, Theme } from "@material-ui/core/styles";
import { Dialog, DialogContent, withStyles } from "@material-ui/core";
import { NewPostCreationSteps } from "./enums";
import NewPostController from "./NewPostController.web";
import NewPostAddCaption from "./NewPostAddCaption.web";
import NewPostCover from "./NewPostCover.web";
import NewPostPreview from "./NewPostPreview.web";
import FileDropzoneModal from "./FileDropzoneModal.web";
import EverybrandLibrary from "./EverybrandLibrary.web";
import InstagramLibrary from "./InstagramLibrary.web";
import TikTokLibrary from "./TikTokLibrary.web";

const styles = (_theme: Theme) =>
  createStyles({
    postDialogPaper: {
      borderRadius: "8px 8px 32px 8px",
      maxWidth: "810px",
      "& > .MuiDialogContent-root": {
        padding: "0px",
      }
    }
  });

export class NewPost extends NewPostController {
  render() {
    const { classes, navigation } = this.props;
    const {
      currentStep,
      files,
      cover,
      poll,
      captionView,
      caption,
      bodyText,
      isCommentsAllowed,
    } = this.state;

    return (
      <>
        <CssBaseline />
        <Dialog
          open
          fullWidth
          maxWidth="md"
          classes={{
            paper: classes.postDialogPaper
          }}
          onClose={this.closeModal}
        >
          <DialogContent>
            {currentStep === NewPostCreationSteps.SELECT_FILE && (
              <FileDropzoneModal
                postType="post"
                files={files}
                setFiles={this.setFiles}
                setStep={this.setStep}
                closeModal={this.closeModal}
                openLibrary={() => this.setStep(NewPostCreationSteps.EVERYREEL_LIBRARY)}
              />
            )}
            {currentStep === NewPostCreationSteps.EVERYREEL_LIBRARY && (
              <EverybrandLibrary goBack={this.handleSetMediaSelectionStep} setFiles={this.setFiles}/>
            )}

            {currentStep === NewPostCreationSteps.ADD_CAPTION && (
              <NewPostAddCaption
                files={files}
                poll={poll}
                view={captionView}
                caption={caption}
                setStep={this.setStep}
                closeModal={this.closeModal}
                setCaption={this.setCaption}
                setPollQuestion={this.setPollQuestion}
                setPollAnswer1={this.setPollAnswer1}
                setPollAnswer2={this.setPollAnswer2}
                setCaptionView={this.setCaptionView}
              />
            )}
            {currentStep === NewPostCreationSteps.COVER_SELECTION && (
              <NewPostCover
                files={files}
                setStep={this.setStep}
                postCaption={bodyText}
                allowComments={this.allowComments}
                isCommentsAllowed={isCommentsAllowed}
                closeModal={this.closeModal}
                caption={caption}
                setCover={this.setCover}
                sendPost={this.sendPost}
                postBodyMessage={this.state.postBodyMessage}
                cover={cover}
                handlePostCaptionChange={this.handlePostCaptionChange}
              />
            )}
            {currentStep === NewPostCreationSteps.POST_PREVIEW && (
              <NewPostPreview
                files={files}
                cover={cover}
                caption={bodyText}
                closeModal={this.closeModal}
                setStep={this.setStep}
                sendPost={this.sendPost}
              />
            )}
          </DialogContent>
          {currentStep === NewPostCreationSteps.INSTAGRAM_LIBRARY && (
              <InstagramLibrary
                navigation={navigation}
                setFiles={this.setFiles}
                handleGoBack={this.handleSetMediaSelectionStep}
              />
            )}
            {currentStep === NewPostCreationSteps.TIK_TOK_LIBRARY && (
              <TikTokLibrary
                navigation={navigation}
                setFiles={this.setFiles}
                handleGoBack={this.handleSetMediaSelectionStep}
              />
            )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(NewPost));
// Customizable Area End
